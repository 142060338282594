import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import ValidationText from '../../../utils/validation/ValidationText'
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'
import Selector from '../../dropdown/commonDropdown/Selector'
import CheckboxInput from '../../inputs/CheckboxInput'
//import { AddEditFinanceExternalServiceDetail } from '../../../services/axiosServices/ApiEndPoints'

function AddEditNewProfile(props) {
  return (
    <ModalBasic
      id="show-individual-profile-modal"
      modalOpen={props.addEditNewProfile}
      setModalOpen={props.setOpenModal}
    >
      <div className="bg-white mb-10">
        <div className="">
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-3 pr-0">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <h1 className="text-4xl text-[#005B9C] font-bold leading-loose py-4">
                Individual Profile
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-3 pr-0">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    First Name <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-[130px] individual-f-name">
                      <DropdownSelect
                        drpIdentity={"Org_MrMrs"}
                        optionArray={props.mrMrs}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organisationDetails.mrMrs}
                      />
                    </div>
                    <div className="w-full">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="firstName"
                        value={props.organisationDetails.firstName}
                        identity="Organisation"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("firstName", props.organisationDetails)}
                      />
                    </div>
                  </div>
                  <ValidationText error={props.validState.error.mrMrs} />
                  <ValidationText error={props.validState.error.firstName} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    Last Name <span className="text-[#c00000]">*</span>
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="lastName"
                    value={props.organisationDetails.lastName}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("lastName", props.organisationDetails)}
                  />
                  <ValidationText error={props.validState.error.lastName} />
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    Job Title<span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="dropdwn-input-email flex items-center">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="designation"
                      value={props.organisationDetails.designation}
                      identity="Organisation"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validate("designation", props.organisationDetails)}
                    />
                  </div>
                  <ValidationText error={props.validState.error.designation} />
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    Direct Office Number
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="directOfficeNumber"
                    value={props.organisationDetails.directOfficeNumber}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("directOfficeNumber", props.organisationDetails.directOfficeNumber)}
                  />
                  <ValidationText error={props.validState.error.directOfficeNumber} />
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    Mobile Number
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="contactNo"
                    value={props.organisationDetails.contactNo}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("contactNo", props.organisationDetails)}
                  />
                  <ValidationText error={props.validState.error.contactNo} />
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    Email Address{" "}
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full right-border-none">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="email"
                        value={props.organisationDetails.email}
                        identity="Organisation"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("email", props.organisationDetails)}
                        autoComplete={"off"}
                      />
                    </div>
                  </div>
                  <ValidationText error={props.validState.error.email} />
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-1">
                    Organisation Role
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <Selector
                    drpIdentity="Org_OrganisationRoles"
                    options={props.organizationRoleArray}
                    //isMulti
                    //   options={props.countriesWithPresence}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{ Option }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={false}
                    value={
                      props.organisationDetails.selectedOrganisationRole
                    }
                  />
                  <ValidationText error={props.validState.error.selectedOrganisationRole} />
                </div>
              </div>
            </div>


            <div className="lg:col-span-6 col-span-12 input-sec-texts">
              <div className='flex items-center justify-between'>
                <div className="check-box-sec flex items-center register-desc">
                  <input
                    id="isNewsletter"
                    name="isNewsletter"
                    type="checkbox"
                    className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                    checked={props.organisationDetails.isNewsletter}
                    value={props.organisationDetails.isNewsletter}
                    onChange={(event) => props.handleChangeInCheckboxForAuthorisedRepresentative(event)}
                  />
                  <span className="filter-lable font-bold text-[#757575] pl-4">
                    Newsletter
                  </span>
                </div>
                <div className="check-box-sec flex items-center register-desc">
                  <input
                    id="isTelegram"
                    name="isTelegram"
                    type="checkbox"
                    className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                    checked={props.organisationDetails.isTelegram}
                    value={props.organisationDetails.isTelegram}
                    onChange={(event) => props.handleChangeInCheckboxForAuthorisedRepresentative(event)}
                  />
                  <span className="filter-lable font-bold text-[#757575] pl-4">
                    Telegram
                  </span>
                </div>
                <div className="check-box-sec flex items-center register-desc">
                  <input
                    id="isePortal"
                    name="isePortal"
                    type="checkbox"
                    className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                    checked={props.organisationDetails.isePortal}
                    value={props.organisationDetails.isePortal}
                    onChange={(event) => props.handleChangeInCheckboxForAuthorisedRepresentative(event)}
                  />
                  <span className="filter-lable font-bold text-[#757575] pl-4">
                    e-Portal
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete and Save Button */}
        <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-between">
              <div className="">
                {
                  props.organisationDetails && props.organisationDetails.organisationRoleId > 0 ?
                    <button
                      className="p-3 bg-zinc-500 text-lg text-white font-bold"
                      onClick={() => { props.deleteOrganisationRole(props.organisationDetails.organisationRoleId) }}
                    >
                      Delete Profile
                    </button>
                    :
                    null
                }
              </div>
              {
                props.isAddEditAOrganisation && props.isAddEditAOrganisation === true ?
                  (
                    <div className="savebtn">
                      <ButtonLoader type='btn btn-blue' />
                    </div>
                  )
                  :
                  (
                    <div className="savebtn">
                      <button
                        className=" px-14 py-3 bg-[#005B9C] text-xl text-white font-semibold"
                        onClick={() => props.addEditOraganisation("Save")}
                      >
                        Save
                      </button>
                    </div>
                  )
              }
              {/* <div className="savebtn">
                <button
                  className=" px-14 py-3 bg-[#005B9C] text-xl text-white font-semibold"
                  onClick={() => props.addEditOraganisation("Save")}
                >
                  Save
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  )
}

export default AddEditNewProfile