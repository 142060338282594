// Import react libraries and components
import React from 'react';
import moment from 'moment';
import { MultiSelectDropdown, NumberInput, RadioButton, TextInput } from '../../allTypesInputComponents/AllTypeInputComponents';
import ValidationText from '../../../utils/validation/ValidationText';
import DatePicker from '../../datePicker/DatePicker';
import ImageCropperWithUpload from '../../ImageUpload/UploadImage';
import ButtonLoader from '../../common/buttonLoader/ButtonLoader';
// import { Image } from "next/image";

export default function CONQUASBasicApplication(props) {
    // const [] = useState(false);
    return (
        <>
            <div className='container mx-auto pb-10'>
                <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                    <div className='grid grid-cols-12 gap-4'>
                        <div className='col-span-12'>
                            <div className='md:mt-5 mt-5 bg-gray-box '>
                                <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-3 mb-3">
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Level
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="conquasLevelId"
                                                    options={props.levelList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    //onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.levelList.filter(level => level.value === props.basicApplicationDetails.conquasLevelId)}
                                                    onBlur={() => props.validateField("Level", "Basic_Application")}
                                                    disabled={true}
                                                    autoComplete={"off"}
                                                />
                                                <ValidationText error={props.validStateCONQUASMember.error.conquasLevelId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Name to be Printed on Certificate
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <TextInput
                                                    name="nameOnCertificate"
                                                    className='w-full'
                                                    value={props.basicApplicationDetails.nameOnCertificate}
                                                    identity="nameOnCertificate"
                                                    handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                                // onBlur={() => props.validateField("nameOnCertificate", "Basic_Application")}
                                                />
                                                <ValidationText error={props.validStateCONQUASMember.error.nameOnCertificate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-12 mb-3">
                                    <div className="col-span-12">
                                        <div className='form-info-title'>
                                            <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                                                Personal Particular
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-3 mb-3">
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            First Name
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="firstName"
                                                className='w-full'
                                                value={props.basicApplicationDetails.firstName}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            //onBlur={() => props.validateField("firstName", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.firstName} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Last Name
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                name="lastName"
                                                value={props.basicApplicationDetails.lastName}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            // onBlur={() => props.validateField("lastName", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.lastName} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Date of Birth
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <DatePicker
                                                name="dateOfBirth"
                                                className='w-full'
                                                value={moment(props.basicApplicationDetails.dateOfBirth).format("YYYY-MM-DD")}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            // onBlur={() => props.validateField("dateOfBirth", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.dateOfBirth} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Identification No (Last 3 digit & alphabets)
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                name="identificationNo"
                                                value={props.basicApplicationDetails.identificationNo}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            // onBlur={() => props.validateField("identificationNo", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.identificationNo} />
                                        </div>

                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Sex
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="flex items-center w-full">
                                            <div className="flex items-center">
                                                <RadioButton
                                                    id="isSexMale"
                                                    name="isSexMale"
                                                    className="form-radio-conquas"
                                                    //value={props.basicApplicationDetails.isSexMale}
                                                    checked={props.basicApplicationDetails.isSexMale === true}
                                                    handleRadioButtons={(e) => props.handleRadioButtons(e, "CONQUASBasicApplication")}
                                                />
                                                <span className="ml-3 text-[14px]">
                                                    Male
                                                </span>
                                            </div>
                                            <div className="flex items-center ml-10">
                                                <RadioButton
                                                    id="isSexMale"
                                                    name="isSexMale"
                                                    className="form-radio-conquas"
                                                    //value={props.basicApplicationDetails.isSexMale}
                                                    checked={props.basicApplicationDetails.isSexMale === false}
                                                    handleRadioButtons={(e) => props.handleRadioButtons(e, "CONQUASBasicApplication")}
                                                />
                                                <span className="ml-3 text-[14px]">
                                                    Female
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Billing Address Line 1
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                name="homeAddressLine1"
                                                value={props.basicApplicationDetails.homeAddressLine1}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            // onBlur={() => props.validateField("homeAddressLine1", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.homeAddressLine1} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Billing Address Line 2
                                            {/* <span className="text-[#C00000]">*</span> */}
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                name="homeAddressLine2"
                                                value={props.basicApplicationDetails.homeAddressLine2}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Billing Address Line 3
                                            {/* <span className="text-[#C00000]">*</span> */}
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                name="homeAddressLine3"
                                                value={props.basicApplicationDetails.homeAddressLine3}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Postal Code
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <NumberInput
                                                placeholder=""
                                                name="postalCode"
                                                value={props.basicApplicationDetails.postalCode}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            // onBlur={() => props.validateField("postalCode", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.postalCode} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Country/Region
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="Country"
                                                    options={props.countryList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.countryList.filter(country => country.value === props.basicApplicationDetails.countryId)}
                                                />
                                                <ValidationText error={props.validStateCONQUASMember.error.countryId} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Contact Number (Home)
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <NumberInput
                                                placeholder=""
                                                name="contactNumberHome"
                                                value={props.basicApplicationDetails.contactNumberHome}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            //onBlur={() => props.validateField("contactNumberHome", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.contactNumberHome} />
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Contact Number (Office)
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <NumberInput
                                                placeholder=""
                                                name="contactNumberOffice"
                                                value={props.basicApplicationDetails.contactNumberOffice}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            //onBlur={() => props.validateField("contactNumberOffice", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.contactNumberOffice} />
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Mobile Number
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <NumberInput
                                                placeholder=""
                                                name="mobileNumber"
                                                value={props.basicApplicationDetails.mobileNumber}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            //onBlur={() => props.validateField("mobileNumber", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.mobileNumber} />
                                        </div>
                                    </div>


                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Email Address
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="email"
                                                className='w-full'
                                                value={props.basicApplicationDetails.email}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASBasicApplication")}
                                            //onBlur={() => props.validateField("email", "Basic_Application")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.email} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Country of Birth
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="CountryOfBirth"
                                                    options={props.placeOfBirthList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.placeOfBirthList.filter(birthPlace => birthPlace.value === props.basicApplicationDetails.countryOfBirth)}
                                                />
                                                <ValidationText error={props.validStateCONQUASMember.error.countryOfBirth} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Nationality
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="Nationality"
                                                    options={props.nationalityList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.nationalityList.filter(nationality => nationality.value === props.basicApplicationDetails.conquasNationalityId)}
                                                />
                                                <ValidationText error={props.validStateCONQUASMember.error.conquasNationalityId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pt-4 2x2:col-span-6 lg:col-span-12 md:col-span-12 col-span-12 mt-6 input-sec-texts">
                                        <div className='flex 2x2: items-center mb-4'>
                                            <div class="flex items-center w-auto h-6 ">
                                                <img
                                                    src={props && props.basicApplicationDetails.fullFacePhoto ? '/check-skyblue.png' : '/checkNot.png'}
                                                    className='w-[25px] mr-3'
                                                    alt='' />
                                                <span className='text-[#000]'>
                                                    Upload Full Face Photo/Passport Photo
                                                </span>
                                                <form className="custom-uploader custom-file-upload-btn flex cursor-pointer">
                                                    <ImageCropperWithUpload
                                                        key="Main"
                                                        keyId="_Full_Face_Photo"
                                                        height={10}
                                                        width={10}
                                                        // isCheckValidation={true}
                                                        MaxFileSize={5242870}
                                                        MaxFileSizeMessage={"5MB"}
                                                        onSaveImage={props.saveMainImage}
                                                        //onSaveImage={props.onFileChange}
                                                        imageIdentity="CONQUASMemberData"
                                                        intitImage={props.basicApplicationDetails.fullFacePhoto}
                                                        aspactRatio={550 / 550}
                                                        uploadBtn={"Upload Photo"}
                                                        className="sr-only"
                                                        id="Full_Face_Photo"
                                                    //type="file"
                                                    //onChange={(event) => props.onFileChange(event, "Full_Face_Photo")}
                                                    // disabled={props.isDisabledFlag ? true : false}
                                                    // dimensionText={
                                                    // "(Dimension: 200px by 200px Maximum Size: 2MB)"
                                                    // }
                                                    />
                                                    {/* <ImageCropperWithUpload
                                                        key="Main"
                                                        height={10}
                                                        width={10}
                                                        isCheckValidation={true}
                                                        MaxFileSize={2097152}
                                                        MaxFileSizeMessage={"2MB"}
                                                        onSaveImage={props.saveMainImage}
                                                        imageIdentity="IndividualMembers"
                                                        intitImage={props.individualSignUpDetails.photo}
                                                        aspactRatio={550 / 550}
                                                        uploadBtn={"Upload Photo"}
                                                    // dimensionText={
                                                    //   "(Dimension: 200px by 200px Maximum Size: 2MB)"
                                                    // }
                                                    /> */}

                                                </form>
                                                <button className="ml-0 2xl:px-5 w-[150px] lg:px-5 py-2 btn btn btn-blue-border text-xl border text-[#4687C6]"
                                                    onClick={() => props.GetCONQUASMemberFiles('FullFacePhoto', props.basicApplicationDetails.fullFacePhoto)}
                                                >
                                                    View Photo
                                                </button>
                                            </div>

                                            <div className="ml-5">
                                                {/* <ValidationText error={props.validStateCONQUASMember.error.fullFacePhoto} /> */}
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div class={`flex-initial w-auto`}>
                                                {/* {props && props.basicApplicationDetails.fullFacePhoto ? (
                                                    // <ButtonLoader type="btn btn-conquas" />
                                                    // ) : (
                                                    <form className="custom-uploader custom-file-upload-btn flex cursor-pointer">
                                                        <ImageCropperWithUpload
                                                            key="Main"
                                                            height={10}
                                                            width={10}
                                                            // isCheckValidation={true}
                                                            MaxFileSize={5242870}
                                                            MaxFileSizeMessage={"5MB"}
                                                            onSaveImage={props.saveMainImage}
                                                            imageIdentity="CONQUASMemberData"
                                                            intitImage={props.basicApplicationDetails.fullFacePhoto}
                                                            aspactRatio={550 / 550}
                                                            uploadBtn={"Upload Photo"}
                                                            className="sr-only"
                                                            id="Full_Face_Photo"
                                                            type="file"
                                                            onChange={(event) => props.onFileChange(event, "Full_Face_Photo")}
                                                        // disabled={props.isDisabledFlag ? true : false}
                                                        // dimensionText={
                                                        //   "(Dimension: 200px by 200px Maximum Size: 2MB)"
                                                        // }
                                                        />
                                                    </form>
                                                )
                                                    :
                                                    (
                                                        // <button className="ml-5 2xl:px-5 w-[150px] lg:px-5 py-2 btn btn btn-blue-border text-xl border text-[#4687C6]"
                                                        //     // onClick={() => props.GetCorporateMemberFiles(true)} 
                                                        //     // disabled={props.organizationBasicInformation.organisationInformationId ? false : true}
                                                        //     onClick={() => setImageViewer(true)}
                                                        // >
                                                        //     <span className="text-xl text-[#4687C6] break-words	 font-semibold tracking-normal underline">
                                                        //         View Photo
                                                        //     </span>
                                                        // </button>
                                                        <button className="ml-5 2xl:px-5 w-[150px] lg:px-5 py-2 btn btn btn-blue-border text-xl border text-[#4687C6]"
                                                            onClick={() => props.GetCONQUASMemberFiles('FullFacePhoto', props.basicApplicationDetails.fullFacePhoto)}
                                                        >
                                                            View Photo
                                                        </button>
                                                    )} */}


                                                {/* {
                                                    props && props.isFullFacePhoto ?
                                                        <ButtonLoader
                                                            className='ml-10 btn btn-blue'
                                                        />
                                                        :
                                                        (
                                                            <form className={`custom-uploader custom-file-upload-btn flex cursor-pointer lg:ml-2 md:mt-0 mt-2 `}>
                                                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px] ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                                                    <label htmlFor="Full_Face_Photo" className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload Photo</label>
                                                                    <input className="sr-only" id="Full_Face_Photo" type="file"
                                                                        onChange={(event) => props.onFileChange(event, "Full_Face_Photo")}
                                                                        disabled={props.isDisabledFlag ? true : false}
                                                                    />
                                                                </div>
                                                            </form>
                                                        )
                                                } */}
                                            </div>

                                            {/* {props && props.basicApplicationDetails.fullFacePhoto ?
                                                <div class="w-auto">
                                                    <button className="ml-5 2xl:px-5 w-[150px] lg:px-5 py-2 btn btn btn-blue-border text-xl border text-[#4687C6]"
                                                        onClick={() => props.GetCONQUASMemberFiles('FullFacePhoto', props.basicApplicationDetails.fullFacePhoto)}
                                                    >
                                                        <span className="text-xl text-[#4687C6] break-words	 font-semibold tracking-normal underline">
                                                            View Files
                                                        </span>
                                                    </button>
                                                </div>
                                                :
                                                null
                                            } */}
                                        </div>
                                        <div class="ml-1 mt-5 mb-2">{props.basicApplicationDetails.fullFacePhoto}</div>

                                    </div>

                                    {/* <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                    <h2 className="input-title mb-3">
                                        Upload Full Face Photo/Passport Photo
                                        <span className="text-[#C00000]"> *</span>
                                    </h2>
                                    <div className="dropdown">
                                        <div className="select-dropdown w-full">
                                            <form className="custom-uploader custom-file-upload-btn flex">
                                                <div className='border border-[#f3f3f3] btn btn-conquas cursor-pointer'>
                                                    <label
                                                        htmlFor={`Full_Face_Photo`}
                                                        className="text-uplod text-white cursor-pointer"
                                                    >
                                                        Upload File
                                                    </label>
                                                    <input
                                                        className="sr-only"
                                                        id={`Full_Face_Photo`}
                                                        type="file"
                                                        // onChange={(event) => props.onFileChange(event, "Upload_Full_Face_Photo")}
                                                    />
                                                </div>
                                            </form>
                                            {
                                                props && props.basicApplicationDetails.fullFacePhoto ?
                                                    (
                                                        <div className='mt-5 file-uploaded-txt flex items-center'>
                                                            <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' />
                                                            <span className='text-[#000]'>
                                                                {props.basicApplicationDetails.fullFacePhoto}
                                                            </span>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                            }
                                            <ValidationText error={props.validStateCONQUASMember.error.fullFacePhoto} />
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                                {/* <div className="col-span-12 mt-10">
                                <div className='flex justify-center'>
                                    {
                                        props && props.isLoading ?
                                            <ButtonLoader type='btn btn-conquas' />
                                            :
                                            (
                                                <button
                                                    className='btn btn-conquas'
                                                    // onClick={() => props.submitBasicApplication("isSave")}
                                                >
                                                    <span className=""
                                                    // onClick={() => { Navigate(Routes.OrganizationProfile) }}
                                                    >
                                                        Submit
                                                    </span>
                                                </button>
                                            )
                                    }
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="flex gap-3 items-center justify-between mt-3">
                            {props.isLoading ? (
                                <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue" />
                            ) : (
                                <button
                                    className={`col-end-9 col-span-2 text-xl font-bold btn btn-blue w-[250px] p-3 mt-4 `}

                                    onClick={() => {
                                        props.updateCONQUASPersonalParticularDetail()
                                    }}
                                // disabled={this.state.isDisabledFlag ? true : false}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {/* <ImageViewerConquas
                    ImageViewerModal={imageViewer}
                    conquasImage={props.basicApplicationDetails}
                    setOpenModal={() => setImageViewer(false)}
                    onClose={(e) => {
                        e.stopPropagation();
                        setImageViewer(false);
                    }}
                /> */}
            </div>

        </>
    )
}