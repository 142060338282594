// // Import react libraries and components
import React, { Component } from "react";
import CONQUASBasicApplication from "../../../components/membership/conquasMembers/CONQUASBasicApplication";
import CONQUASQualificationsProfile from "../../../components/membership/conquasMembers/CONQUASQualificationsProfile";
import CONQUASTrainingInformation from "../../../components/membership/conquasMembers/CONQUASTrainingInformation";
import { checkParameters } from "../../../utils/Encryption";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import { isValidForm, validate } from "../../../utils/validation/CommonValidator";
import CONQUASMemberService from "../../../services/axiosServices/apiServices/CONQUASMemberServices";
import {
  CONQUASMemberValidationRules,
  CONQUASAcademicQualificationsValidationRules,
  CONQUASTrainingWorkshopValidationRules,
  CONQUASGIPWorkshopValidationRules,
  CONQUASSupervisionExperienceValidationRules,
} from "./CONQUASMemberValidationRules";

// // Import API services
import SwalServices from "../../../services/swalServices/SwalServices";
import { Navigate } from "react-router-dom";
import { CommonSuccessMessages } from "../../../utils/Messages";
import SendClarifications from "./SendClarifications";
import SendRejectionReason from "./SendRejectionReason";
import { saveAs } from "file-saver";
import moment from "moment/moment";
import UpdateLOA from "./UpdateLOA";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class CONQUASApplication extends Component {
  constructor(props) {
    super(props);
    this.swalServices = new SwalServices();
    this.conquasMemberService = new CONQUASMemberService();
    this.scrollRef = React.createRef();
    this.state = {
      validState: {
        isValid: true,
        error: {},
      },
      route: null,
      registrationSuccessModal: false,
      isLoading: false,
      isWPRecordLoader: false,
      is6MonCPFLoader: false,
      isCPFConLoader: false,
      isApprovedBCALoader: false,
      isBankAccStatLoader: false,
      isOnGoingProLoader: false,
      isOnGoingProLoader2: false,
      isCompProLoader: false,
      isCompProLoader2: false,
      isDraftLoader: false,
      isSubmitLoader: false,
      isPaymentProofLoader: false,
      isACRACerLoader: false,
      adminName: "",
      updatedAppId: 0,
      updatedBy: 0,
      openTab: 1,
      isLogin: false,
      isLoadingRejectAplication: false,
      isLoadingLOA: false,
      isCONQUASBasicApplicationLoading: false,
      isSendPaymentLinkLoader: false,
      isAcceptAndApprovedLoader: false,
      isSendRenewalPaymentLinkLoader: false,
      isBackFromClarificationLoader: false,
      clarification: "",
      // isLettersOfEmploymentSupervision: false,
      // All List 
      // Basic Application Detail List
      applicationTirelist: [
        {
          value: 1,
          label: 'Application for Tire 1'
        },
        {
          value: 2,
          label: 'Application for Tire 2'
        },
        {
          value: 3,
          label: 'Application for Tire 3'
        },
        {
          value: 4,
          label: 'Application for Tire 4'
        },
        {
          value: 5,
          label: 'Application for Tire 5'
        },
      ],
      levelList: [],
      countryList: [],
      placeOfBirthList: [],
      nationalityList: [],
      // Educational Information List
      academicQualificationList: [],
      courseDurationList: [],
      trainingWorkshopList: [],
      gipWorkshopList: [],
      // CONQUAS login states
      conquasLogIn: {
        uenNumber: "",
        password: "",
      },
      // Basic Application object
      basicApplicationDetails: {
        conquasMemberId: 0,
        //applicationTireId: 0,
        conquasLevelId: 0,
        levelId: 0,
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        identificationNo: "", //dateJoinedCompany
        isSexMale: true,
        homeAddressLine1: "",
        homeAddressLine2: "",
        homeAddressLine3: "",
        postalCode: "",
        countryId: 0,
        contactNumberHome: "",
        contactNumberOffice: "",
        mobileNumber: "",
        email: "",
        countryOfBirth: 0, //placeOfBirthId: 0,
        conquasNationalityId: 0, //nationalityId: 0,
        fullFacePhoto: "", //imagePath: "",
        isTermAndConditions1: false,
        isTermAndConditions2: false,
        statusId: 0,
        loa: "",
        clarification: "",
        nameOnCertificate: "",
        lettersOfEmployment:""
      },
      isAddedLOANotes: false,
      educationInformationCONQUASTrainingList: [],
      educationInformationCONQUASGIPWorkshopList: [],
      educationInformationList: [],
      conquasSupervisonExperienceList: [],
      gipWorkshopListForCounting: [],
      conquasMemberId: 0,
      isFullFacePhoto: false,
      isUploadAcademicQualifications: false,
      isUploadAcademicQualificationsConquasTraining: false,
      isUploadAcademicQualificationsConquasGIP: false,
      isSupervisionExperienceAndCourseCertificates: false,
      isLettersOfEmploymentSupervision: false,
      // Educational Information Object
      //educationalInformation: CONQUASEducationalInformationObject,
      educationalInformation: {
        educationalInformation1: [],
        educationalInformation2: [],
        educationalInformation3: [],
      },
      //detail1: CONQUASEducationInformation1Object,
      detail1: {
        academicProfessionalQualificationsIndustrialTrainingId: 0,
        nameOfInstitution: "",
        //academyQualification: "",
        academicQualificationsAndCourseCertificate: "",
        courseDurationId: 0,
        academicProfessionalQualificationsId: 0,
        courseDurationLabel: "",
        academicProfessionalQualificationsLabel: "",
        academicProfessionalQualificationsName: "",
        durationOfCourseStartDate: "",
        durationOfCourseEndDate: "",

      },
      detail2: {
        conquasTrainingWorkshopId: 0,
        nameOfInstitutionForTrainingWorkshop: "",
        workshopTitle: "",
        courseDurationId: 0,
        courseDurationLabel: "",
        durationOfCourseStartDate: "",
        durationOfcourseEndDate: "",
        trainingWorkshopForId: 0,
        academyQualificationId: 0,
        conquasTrainingWorkshopForId: 0,
        cONQUASTrainingWorkshopForLabel: "",
        conquasTrainingWorkshopForName: "",
        //academyQualificationPath: "",
        academicQualificationAndCourseCertificates: "",
      },
      detail5: {
        educationalProfessionalQualificationsGIPWorkshopId: 0,
        nameOfInstitution: "",
        workshopTitle: "",
        courseDurationId: 0,
        courseDurationLabel: "",
        durationOfCourseStartDate: "",
        durationOfcourseEndDate: "",
        gipWorkshopForId: 0,
        academyQualificationId: 0,
        conquasgipWorkshopForId: 0,
        cONQUASGIPWorkshopForLabel: "",
        conquasgipWorkshopForName: "",
        //academyQualificationPath: "",
        academicQualificationsAndCourseCertificate: "",
        conquasGIPWorkshopDropdownList: [],
        gipWorkshopPoints: 0,
      },
      // Employment Information Object
      //employmentInformation: CONQUASEmploymentInformationObject,
      employmentInformation: {
        // currentEmploymentInfo: [],
        nameOfCurrentEmployer: "",
        officeAddress: "",
        officeAddressLine2: "",
        officeAddressLine3: "",
        designation: "",
        dateJoinedCompany: "",
        lettersOfEmployment: "",
        supervisionExperienceList: [],
        isQMProject: true,
      },
      //detail3: CONQUASCurrentEmplymentInfoObject,
      detail3: {
        nameOfEmployer: "",
        letterOfEmploymetPath: "",
      },
      //detail4: CONQUASDetailsCurrentAndPastSiteSupervisionExperienceObject,
      detail4: {
        conquasSupervisionExperienceId: 0,
        nameOfProject: "",
        costOfProject: "",
        titleOfPosition: "",
        dateFrom: "",
        dateTo: "",
        nameOfEmployer: "",
        addressOfEmployer: "",
        // scoreOfProject: "",
        conquasScoreOfProject: "",
        // certificatePath: "",
        academicQualificationsAndCourseCertificates: "",
        lettersOfEmployment: "",
        isQMProject: true,
        descriptionOfProject: "",
      },
      // Summary Details Object
      //summaryDetails: CONQUASSummaryDetailsObject,
      summaryDetails: {
        isRequiredQuotation1: false,
        isRequireQuotation2: false,
      },
      //conquasMemberId: 0,
      characterLimit: 500,
      validStateRetriveCONQUAS: {
        isValid: true,
        error: {},
      },
      validStateBasicApplication: {
        isValid: true,
        error: {},
      },
      validStateEducationInformation: {
        isValid: true,
        error: {},
      },
      validStateEducationInformationWorkshop: {
        isValid: true,
        error: {},
      },
      validStateEmployementInformation: {
        isValid: true,
        error: {},
      },
      validStateSupervisionExperience: {
        isValid: true,
        error: {},
      },
      // Valid State Object
      validStateCONQUASMember: {
        isValid: true,
        error: {},
      },
      validStateCONQUASAcademicQualifications: {
        isValid: true,
        error: {},
      },
      validStateCONQUASTrainingWorkshop: {
        isValid: true,
        error: {},
      },

      validStateCONQUASGIPWorkshop: {
        isValid: true,
        error: {},
      },
      validStateCONQUASSupervisionExperience: {
        isValid: true,
        error: {},
      },
      setSendClarifications: false,
      setRejectionReason: false,
      sendRejectionReason: "",
      setUpdateLOA: false,

      requiredForCONQUASSupervisorAndManager: [1],
      requiredForQualityMarkSupervisorAndManager: [1, 2, 3, 4],
      requredGIPMinimumPointsForSupervisorAndManager: 3,
      requredGIPMinimumPointsForQualityMarkSupervisorAndManager: 6,
      validationRuleForCONQUASSupervisorAndManager:
      {
        requiredToSelected: [],
        isValid: true,
        error: {},
        RequiredPoints: 0,
        isQualityMark: false
      },
      rulesForCONQUASSupervisorAndManager: {
        waterproofingToInternalWetAreas: "Waterproofing To Internal Wet Areas is a compulsory requirement.",
        isWaterproofingToInternalWetAreasSelected: false,
        miniAchievePoints: "Please ensure that you achieve 3 Trades.",
        isMiniAchievePoints: false,
        // mitMinimumCriteria: "You have met the minimum criteria.",
        // isMitMinimumCriteria: false,

        // password: [
        //     {
        //         type: "require",
        //         message: Messages.CommonValidationMessages.FieldRequired.replace("{0}", "password"),
        //     }
        // ],
      },
      isValidateGIPCalculation: false,
      rulesForQualitySupervisorAndManager: {
        waterproofingToInternalWetAreas: "Waterproofing To Internal Wet Areas is a compulsory requirement.",
        isWaterproofingToInternalWetAreasSelected: false,
        marbleGraniteCeremic: "Marble/Granite/Ceremic Tiling is a compulsory requirement.",
        isMarbleGraniteCeremicSelected: false,
        aluminiumWindows: "Aluminium Windows is a compulsory requirement",
        isAluminiumWindowsSelected: false,
        timberFlooring: "Timber Flooring is a compulsory requirement.",
        isTimberFlooringSelected: false,
        miniAchievePoints: "Please ensure that you achieve 6 Trades.",
        isMiniAchievePoints: false,
        // mitMinimumCriteria: "You have met the minimum criteria.",
        // isMitMinimumCriteria: false,
      },
      cONQUASSupervisorAndManagerPoints: 0,
    };
  }

  // CONQUAS Member API Method Section Start
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let cookie = getAuthProps();
    if (cookie !== null) {
      const adminUserId = cookie.adminUserId;
      const adminname = cookie.name;
      this.setState({ updatedBy: adminUserId, updatedAppId: 111, adminName: adminname });
    }

    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[2];
    if (checkFinal === "ReadOnly") {
      this.setState({ isDisabledFlag: true });
    }

    let conquasId = params.get("");
    if (conquasId && (conquasId = checkParameters(conquasId, "Number"))) {
      if (conquasId !== null && conquasId > 0) {
        this.setState({ conquasMemberId: conquasId });
      }
    }
    this.getAllDropDownsForCONQUASMember();

    this.getCONQUASMemberDetailByMemberId(conquasId);
  }


  // Academic_ProfessionalQualificationsDropDown = () => {

  //   let conquasLevelId = this.state.basicApplicationDetails.conquasLevelId;
  //   if (conquasLevelId === 4 || conquasLevelId === 5) {
  //     this.state.academicQualificationList = this.state.academicQualificationList.filter((drp) => drp.value !== 7 && drp.value !== 8);
  //   }
  //   else {
  //     this.state.academicQualificationList = this.state.academicQualificationList.filter((drp) => drp.value !== 1 && drp.value !== 2 && drp.value !== 3 && drp.value !== 4 && drp.value !== 5 && drp.value !== 6 && drp.value !== 9 && drp.value !== 10);
  //   }
  // }

  Academic_ProfessionalQualificationsDropDown = () => {
    let conquasLevelId = this.state.basicApplicationDetails.conquasLevelId;

    this.setState((prevState) => {
      let updatedList;

      if (conquasLevelId === 4 || conquasLevelId === 5) {
        updatedList = prevState.academicQualificationList.filter(
          (drp) => drp.value !== 7 && drp.value !== 8
        );
      } else {
        updatedList = prevState.academicQualificationList.filter(
          (drp) =>
            drp.value !== 1 &&
            drp.value !== 2 &&
            drp.value !== 3 &&
            drp.value !== 4 &&
            drp.value !== 5 &&
            drp.value !== 6 &&
            drp.value !== 9 &&
            drp.value !== 10
        );
      }

      return { academicQualificationList: updatedList };
    });
  };



  // Get All Dropdowns For CONQUAS Member Method
  getAllDropDownsForCONQUASMember = () => {
    this.conquasMemberService.getAllDropDownsForCONQUASMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem !== null) {

        var level = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Level"
        );
        level.map(function (item) {
          return delete item["dropdownName"];
        });
        level = level.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ levelList: level });

        var country = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Country"
        );
        country.map(function (item) {
          return delete item["dropdownName"];
        });
        country = country.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ countryList: country });

        var countryOfBirth = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Country of Birth"
        );
        countryOfBirth.map(function (item) {
          return delete item["dropdownName"];
        });
        countryOfBirth = countryOfBirth.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ placeOfBirthList: countryOfBirth });

        var nationality = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Nationality"
        );
        nationality.map(function (item) {
          return delete item["dropdownName"];
        });
        nationality = nationality.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ nationalityList: nationality });

        var academicQualification = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Academic/Professional Qualifications"
        );
        academicQualification.map(function (item) {
          return delete item["dropdownName"];
        });
        academicQualification = academicQualification.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ academicQualificationList: academicQualification });

        var conquasTraining = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CONQUAS Training Workshop For"
        );
        conquasTraining.map(function (item) {
          return delete item["dropdownName"];
        });
        conquasTraining = conquasTraining.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ trainingWorkshopList: conquasTraining });

        var gipWorkshopList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CONQUAS GIP Workshop For");
        let gipWorkshopListForCounting = gipWorkshopList;
        gipWorkshopList.map(function (item) { return delete item["dropdownName"]; });
        gipWorkshopList = gipWorkshopList.map((p) => ({ value: p.id, label: p.name }));

        gipWorkshopListForCounting.map(function (item) { return delete item["dropdownName"]; });
        gipWorkshopListForCounting = gipWorkshopListForCounting.map((p) => ({ value: p.id, label: p.name, gipWorkshopPoints: p.valueOrdering }));
        this.setState({ gipWorkshopList: gipWorkshopList, gipWorkshopListForCounting: gipWorkshopListForCounting }, () => { console.log("gipWorkshopListForCounting", this.state.gipWorkshopListForCounting) });
      }
    });
  };

  // Get CONQUAS Member Detail By Id
  getCONQUASMemberDetailByMemberId = (id) => {

    this.conquasMemberService.getCONQUASMemberDetailByMemberId(id).then((response) => {

      if ((response.statusCode === 200 && response.responseItem === null) || response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          let res = { ...this.state.basicApplicationDetails };

          // CONQUAS Basic Inforamtion
          res.conquasMemberId = response.responseItem.responseContent.conquasMemberId;
          res.conquasLevelId = response.responseItem.responseContent.conquasLevelId;
          res.firstName = response.responseItem.responseContent.firstName;
          res.lastName = response.responseItem.responseContent.lastName;
          res.dateOfBirth = response.responseItem.responseContent.dateOfBirth;
          res.identificationNo = response.responseItem.responseContent.identificationNo;
          res.isSexMale = response.responseItem.responseContent.isSexMale;
          res.homeAddressLine1 = response.responseItem.responseContent.homeAddressLine1;
          res.homeAddressLine2 = response.responseItem.responseContent.homeAddressLine2;
          res.homeAddressLine3 = response.responseItem.responseContent.homeAddressLine3;
          res.countryId = response.responseItem.responseContent.countryId;
          res.postalCode = response.responseItem.responseContent.postalCode;
          res.contactNumberHome = response.responseItem.responseContent.contactNumberHome;
          res.contactNumberOffice = response.responseItem.responseContent.contactNumberOffice;
          res.mobileNumber = response.responseItem.responseContent.mobileNumber;
          res.email = response.responseItem.responseContent.email;
          res.countryOfBirth = response.responseItem.responseContent.countryOfBirth;
          res.conquasNationalityId = response.responseItem.responseContent.conquasNationalityId;
          res.fullFacePhoto = response.responseItem.responseContent.fullFacePhoto;
          res.lettersOfEmployment = response.responseItem.responseContent.lettersOfEmployment;
          res.nameOfCurrentEmployer = response.responseItem.responseContent.nameOfCurrentEmployer;
          res.officeAddress = response.responseItem.responseContent.officeAddress;
          res.officeAddressLine2 = response.responseItem.responseContent.officeAddressLine2;
          res.officeAddressLine3 = response.responseItem.responseContent.officeAddressLine3;
          res.designation = response.responseItem.responseContent.designation;
          res.dateJoinedCompany = response.responseItem.responseContent.dateJoinedCompany;
          res.statusId = response.responseItem.responseContent.statusId;
          res.loa = response.responseItem.responseContent.loa ? response.responseItem.responseContent.loa : "";
          res.nameOnCertificate = response.responseItem.responseContent.nameOnCertificate;

          // res.uploadPhoto = response.responseItem.responseContent.uploadPhoto;
          let educationalInformation = this.state.educationalInformation
          educationalInformation.educationalInformation1 = response.responseItem.responseContent.educationInformationList;
          educationalInformation.educationalInformation2 = response.responseItem.responseContent.educationInformationCONQUASTrainingList;
          educationalInformation.educationalInformation3 = response.responseItem.responseContent.educationInformationCONQUASGIPWorkshopList;

          // let conquasSupervisonExperienceList = this.state.conquasSupervisonExperienceList
          // conquasSupervisonExperienceList = response.responseItem.responseContent.conquasSupervisonExperienceList;

          this.setState({ isAddedLOANotes: response.responseItem.responseContent.loa && response.responseItem.responseContent.loa !== "" ? true : false })
          this.setState({
            basicApplicationDetails: res,
            // detail1: res,
            // detail2: res,
            // detail3: res,
            // detail4: res,
            // detail5: res,
            employmentInformation: res,
            //educationInformationList: response.responseItem.responseContent.educationInformationList,
            educationalInformation: educationalInformation,
            // educationInformationCONQUASTrainingList: response.responseItem.responseContent.educationInformationCONQUASTrainingList,
            // educationInformationCONQUASGIPWorkshopList: response.responseItem.responseContent.educationInformationCONQUASGIPWorkshopList,
            conquasSupervisonExperienceList: response.responseItem.responseContent.conquasSupervisonExperienceList
          }, () => {

            this.gipWorkshopCalculation();
            this.Academic_ProfessionalQualificationsDropDown();

          });

          // if (res.statusId === 21) {
          //   this.setState({ isDisabledFlag: true });
          // }

        }
      } else {
        this.swalServices.Error(response.message);
      }
      // this.setState({ isLoading: false });
    });
    // }
  };

  getAcademicQualifications = (key) => {

    let qualificationsSettingObjectData = this.state.educationalInformation.educationalInformation1;
    let setData = qualificationsSettingObjectData[key];
    let data = {};
    data.nameOfInstitution = setData.nameOfInstitution;
    data.academicProfessionalQualificationsIndustrialTrainingId = setData.academicProfessionalQualificationsIndustrialTrainingId;
    data.academicProfessionalQualificationsName = setData.academicProfessionalQualificationsName;
    data.academicProfessionalQualificationsId = setData.academicProfessionalQualificationsId;
    data.academicQualificationsAndCourseCertificate = setData.academicQualificationsAndCourseCertificate;
    data.durationOfCourseStartDate = moment(setData.durationOfCourseStartDate).format('YYYY-MM-DD');
    data.durationOfCourseEndDate = moment(setData.durationOfCourseEndDate).format('YYYY-MM-DD'); //durationOfcourseEndDate

    this.setState({ detail1: data });
  };

  getTrainingWorkshop = (key) => {
    debugger
    let qualificationsSettingObjectData = this.state.educationalInformation.educationalInformation2;
    let setData = qualificationsSettingObjectData[key];
    let data = {};
    data.nameOfInstitutionForTrainingWorkshop = setData.nameOfInstitutionForTrainingWorkshop;
    data.workshopTitle = setData.workshopTitle;
    data.conquasTrainingWorkshopId = setData.conquasTrainingWorkshopId;
    data.conquasTrainingWorkshopForName = setData.conquasTrainingWorkshopForName;
    data.conquasTrainingWorkshopForId = setData.conquasTrainingWorkshopForId;
    data.academicQualificationAndCourseCertificates = setData.academicQualificationAndCourseCertificates;
    data.durationOfCourseStartDate = moment(setData.durationOfCourseStartDate).format('YYYY-MM-DD');
    data.durationOfcourseEndDate = moment(setData.durationOfCourseEndDate).format('YYYY-MM-DD');

    this.setState({ detail2: data });
  };

  getTrainingGIPWorkshop = (key) => {
    let qualificationsSettingObjectData = this.state.educationalInformation.educationalInformation3;
    let setData = qualificationsSettingObjectData[key];
    let data = {};
    data.educationalProfessionalQualificationsGIPWorkshopId = setData.educationalProfessionalQualificationsGIPWorkshopId;
    data.nameOfInstitution = setData.nameOfInstitution;
    data.workshopTitle = setData.workshopTitle;
    data.conquasgipWorkshopForName = setData.conquasgipWorkshopForName;
    data.conquasgipWorkshopForId = setData.conquasgipWorkshopForId;
    data.gipWorkshopPoints = setData.gipWorkshopPoints;
    // data.conquasGIPWorkshopDropdownList = [];
    // let arr1 = [];
    // arr1 = data.conquasgipWorkshopForId.split(",");

    // arr1.map((p) => {
    //   let arr2 = {};
    //   arr2 = this.state.gipWorkshopList.filter((y) => y.value === Number(p));
    //   data.conquasGIPWorkshopDropdownList.push(...arr2)
    // });
    // let GIP_Workshop = this.state.detail5;
    //       GIP_Workshop.gipWorkshopPoints = 0;
    //       GIP_Workshop.conquasGIPWorkshopDropdownList.map((x) => {
    //         GIP_Workshop.gipWorkshopPoints = GIP_Workshop.gipWorkshopPoints + this.state.gipWorkshopListForCounting.filter((y) => y.value === x.value)[0].gipWorkshopPoints;
    //       })
    //this.setState({ detail5: GIP_Workshop });
    //data.conquasGIPWorkshopDropdownList = setData.conquasgipWorkshopForId;
    data.academicQualificationsAndCourseCertificate = setData.academicQualificationsAndCourseCertificate;
    data.durationOfCourseStartDate = moment(setData.durationOfCourseStartDate).format('YYYY-MM-DD');
    data.durationOfcourseEndDate = moment(setData.durationOfCourseEndDate).format('YYYY-MM-DD');

    // data.gipWorkshopPoints = 0;
    // data.conquasGIPWorkshopDropdownList && data.conquasGIPWorkshopDropdownList.map((x) => {
    //   data.gipWorkshopPoints = data.gipWorkshopPoints + this.state.gipWorkshopListForCounting.filter((y) => y.value === x.value)[0].gipWorkshopPoints;
    // })
    this.setState({ detail5: data }, () => {

      this.gipWorkshopCalculation();

    });
  };

  getSupervisionExperience = (key) => {

    let qualificationsSettingObjectData = this.state.conquasSupervisonExperienceList;
    let setData = qualificationsSettingObjectData[key];
    let data = {};
    data.conquasSupervisionExperienceId = setData.conquasSupervisionExperienceId;
    data.nameOfProject = setData.nameOfProject;
    data.costOfProject = setData.costOfProject;
    data.titleOfPosition = setData.titleOfPosition;
    data.dateFrom = moment(setData.dateFrom).format('YYYY-MM-DD');
    data.dateTo = moment(setData.dateTo).format('YYYY-MM-DD');
    data.nameOfEmployer = setData.nameOfEmployer;
    data.addressOfEmployer = setData.addressOfEmployer;
    data.conquasScoreOfProject = setData.conquasScoreOfProject;
    data.academicQualificationsAndCourseCertificates = setData.academicQualificationsAndCourseCertificates;
    data.lettersOfEmployment = setData.lettersOfEmployment;

    data.descriptionOfProject = setData.descriptionOfProject;
    data.isQMProject = setData.isQMProject;

    this.setState({ detail4: data });
  };

  SentPaymentLinkOfCONQUASMember = () => {

    this.swalServices
      .Confirm(
        "Are you sure you want to send payment link?",
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.setState({ isSendPaymentLinkLoader: true });
          this.conquasMemberService.SentPaymentLinkOfCONQUASMember(this.state.conquasMemberId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent != null) {
                this.swalServices.Success(
                  "Payment link has been sent."
                );
                this.getCONQUASMemberDetailByMemberId(this.state.conquasMemberId);
              }
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isSendPaymentLinkLoader: false });
          });
        } else {
          this.setState({ isSendPaymentLinkLoader: false });
        }
      });
    this.setState({ isSendPaymentLinkLoader: false });
  };

  sendPaymentLinkForCONQUASRenewalPayment = () => {

    this.swalServices
      .Confirm(
        "Are you sure you want to send renewal payment link?",
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.setState({ isSendRenewalPaymentLinkLoader: true }, () => {
            this.conquasMemberService.sendPaymentLinkForCONQUASRenewalPayment(this.state.conquasMemberId).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                if (response.responseItem.responseContent != null) {
                  this.swalServices.Success(
                    "Renewal payment link has been sent."
                  );
                }
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isSendRenewalPaymentLinkLoader: false });
            });
          });

        } else {
          this.setState({ isSendRenewalPaymentLinkLoader: true });
        }
      });
  };
  RevertToPreviousStatus = () => {
    //debugger
    this.swalServices
      .Confirm(
        "Are you sure, you want to Revert Status ?",
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          let request = {};
          request.CONQUASMemberId = this.state.conquasMemberId

          this.setState({ isBackFromClarificationLoader: true });
          this.conquasMemberService.RevertToPreviousStatus(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent != null) {
                this.swalServices.Success(
                  "Status Reverted as Before the  send clarification"
                );
                this.getCONQUASMemberDetailByMemberId(this.state.conquasMemberId);
              }
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isBackFromClarificationLoader: false });
          });
        } else {
          this.setState({ isBackFromClarificationLoader: true });
        }
      });
  };

  acceptAndApprovedCONQUASApplication = () => {
    this.swalServices
      .Confirm(
        "Are you sure you want to approve CONQUAS applicant?",
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          let request = {};
          request.conquasMemberId = this.state.conquasMemberId
          request.updatedAppId = this.state.updatedAppId;
          request.updatedBy = this.state.updatedBy;
          this.setState({ isAcceptAndApprovedLoader: true });
          this.conquasMemberService.acceptAndApprovedCONQUASApplication(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent != null) {
                this.swalServices.Success(
                  "Application is approved"
                );
                this.getCONQUASMemberDetailByMemberId(this.state.conquasMemberId);
              }
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isAcceptAndApprovedLoader: false });
          });
        } else {
          this.setState({ isAcceptAndApprovedLoader: true });
        }
      });
  };

  onFileChange = (event, fileType) => {

    const file = event.target.files[0];
    if (this.state.conquasMemberId > 0) {
      if (file.size <= 0) {
        this.swalServices.Error("select valid File/ minimum size <0 bytes");
      }
      else {
        if (file.size < 5242880) {
          if (fileType === "Full_Face_Photo") {
            //const file = event.target.files[0];
            if (
              file &&
              (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image.jpg")
            ) {
              let request = {};
              request.storagePath = "CONQUASMemberData";
              request.file = file;
              request.fileName = file.name;
              this.setState({ isFullFacePhoto: true });
              this.conquasMemberService.uploadPDF(request).then((response) => {
                if (response != null) {
                  //if (request.storagePath === "PaymentImages") {
                  let detail = { ...this.state.basicApplicationDetails };
                  detail.fullFacePhoto = response.fileName;
                  //detail["fileDisplayName"] = file.name;
                  this.setState({ basicApplicationDetails: detail }, () => {
                    this.validateField("fullFacePhoto", "Basic_Application")
                    // this.validateCONQUASMember(
                    //   "fullFacePhoto",
                    //   this.state.basicApplicationDetails
                    // );
                    this.saveCONQUASDocuments(
                      "FullFacePhoto",
                      "fullFacePhoto",
                      "Basic_Application"
                    );
                  }
                  );
                  // this.getSLOTSMemberDetailByMemberId(this.state.SLOTSApplicationDetails.slotsMemberId);
                  // }
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isFullFacePhoto: false });
              });
            } else {
              this.swalServices.Error("Please select only Image");
            }
          } else if (fileType === "Academy_Qualification_Training") {

            if ((file && file.type === "application/pdf") || file.type === "application/pdf" ||
              file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "CONQUASMemberData\\AcademyAndProfessionalQualification_IndustrialTraining";
              // Temporary File Select 
              //let detail = this.state.detail2;
              //detail.certificatePath = file.name;
              //this.setState({ detail2: detail }, () => this.validateField("certificatePath", "Educational_Information_Workshop"));
              this.setState({ isUploadAcademicQualifications: true });
              this.conquasMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                  if (response.isNotCurrupted === true) {
                    let detail = this.state.detail1;
                    // detail.uploadWPOnlineRecord = response.fileName;
                    detail.academicQualificationsAndCourseCertificate = response.fileName;
                    //detail.fileListOfWPRecord.push(response.fileName);
                    this.swalServices.Success("File Uploaded");
                    this.setState({ detail1: detail }, () => {
                      this.validateField("academicQualificationsAndCourseCertificate", "Education_Information")
                    });
                  } else {
                    this.swalServices.Error("Invalid Document");
                  }
                  //this.validateField("uploadWPOnlineRecord", "Organisation_Profile");
                  //this.setState({ wPOnlineRecordCount: this.state.wPOnlineRecordCount + 1 });
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isUploadAcademicQualifications: false });
              })
            }
            else {
              this.swalServices.Error("Please upload only PDF!");
            }
          } 
          
          
          
          
          else if (fileType === "Academy_Qualification_Workshop") {

            if ((file && file.type === "application/pdf") || file.type === "application/pdf" ||
              file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "CONQUASMemberData\\AcademyAndProfessionalQualification_Workshop";
              // Temporary File Select 
              //let detail = this.state.detail2;
              //detail.certificatePath = file.name;
              //this.setState({ detail2: detail }, () => this.validateField("certificatePath", "Educational_Information_Workshop"));
              this.setState({ isUploadAcademicQualificationsConquasTraining: true });
              this.conquasMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                  if (response.isNotCurrupted === true) {
                    let detail = this.state.detail2;
                    // detail.uploadWPOnlineRecord = response.fileName;
                    detail.academicQualificationAndCourseCertificates = response.fileName;
                    //detail.fileListOfWPRecord.push(response.fileName);
                    this.swalServices.Success("File Uploaded");
                    this.setState({ detail2: detail }, () => {
                      this.validateField("academicQualificationAndCourseCertificates", "Educational_Information_Workshop")
                    });
                  }
                  else {
                    this.swalServices.Error("Invalid Document");
                  }
                  //this.validateField("uploadWPOnlineRecord", "Organisation_Profile");
                  //this.setState({ wPOnlineRecordCount: this.state.wPOnlineRecordCount + 1 });
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isUploadAcademicQualificationsConquasTraining: false });
              })
            }
            else {
              this.swalServices.Error("Please upload only PDF!");
            }
          }
          else if (fileType === "Academy_Qualification_GIP_Workshop") {

            if ((file && file.type === "application/pdf") || file.type === "application/pdf" ||
              file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "CONQUASMemberData\\AcademyAndProfessionalQualification_GIPWorkshop";
              // Temporary File Select 
              //let detail = this.state.detail2;
              //detail.certificatePath = file.name;
              //this.setState({ detail2: detail }, () => this.validateField("certificatePath", "Educational_Information_GIP_Workshop"));
              this.setState({ isUploadAcademicQualificationsConquasGIP: true });
              this.conquasMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {

                  if (response.isNotCurrupted === true) {
                    let detail = this.state.detail5;
                    // detail.uploadWPOnlineRecord = response.fileName;
                    detail.academicQualificationsAndCourseCertificate = response.fileName;
                    //detail.fileListOfWPRecord.push(response.fileName);
                    this.swalServices.Success("File Uploaded");
                    this.setState({ detail5: detail }, () => {
                      this.validateField("academicQualificationsAndCourseCertificate", "Educational_Information_GIP_Workshop")
                    });
                  }
                  else {
                    this.swalServices.Error("Invalid Document");
                  }
                  //this.validateField("uploadWPOnlineRecord", "Organisation_Profile");
                  //this.setState({ wPOnlineRecordCount: this.state.wPOnlineRecordCount + 1 });
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isUploadAcademicQualificationsConquasGIP: false });
              })
            }
            else {
              this.swalServices.Error("Please upload only PDF!");
            }
          }
          else if (fileType === "AcademicQualificationsAndCourseCertificates") {
            if ((file && file.type === "application/pdf") || file.type === "application/pdf" ||
              file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              file.type === "application/msword") {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "CONQUASMemberData\\AcademicQualificationsAndCourseCertificates";
              // Temporary File Select 
              // let detail = this.state.detail3;
              // detail.letterOfEmploymetPath = file.name;
              // this.setState({ detail3: detail }, this.validateField("letterOfEmploymetPath", "Employment_Information"));
              this.setState({ isSupervisionExperienceAndCourseCertificates: true });
              this.conquasMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                  if (response.isNotCurrupted === true) {
                    let detail = this.state.detail4;
                    // detail.uploadWPOnlineRecord = response.fileName;
                    detail.academicQualificationsAndCourseCertificates = response.fileName;
                    //detail.fileListOfWPRecord.push(response.fileName);
                    this.swalServices.Success("File Uploaded");
                    this.setState({ detail4: detail }, () => {
                      //this.validateField("academicQualificationAndCourseCertificates", "Educational_Information_Workshop")
                      this.validateField("academicQualificationsAndCourseCertificates", "SupervisionExperience")
                    });
                  } else {
                    this.swalServices.Error("Invalid Ducument");
                  }
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isSupervisionExperienceAndCourseCertificates: false });
              })
            }
            else {
              this.swalServices.Error("Please upload only PDF, DOC, DOCX files & Max of 5MB !");
            }
          }
          else if (fileType === "LettersOfEmployment") {

            if ((file && file.type === "application/pdf") || (file.type === "application/pdf") ||
              (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
              (file.type === "application/msword")) {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "CONQUASMemberData\\LettersOfEmploymentSupervision";
              this.setState({ isLettersOfEmploymentSupervision: true });
              this.conquasMemberService.uploadPDF(uploadFile).then((response) => {
                if (response !== null) {
                  if (response.isNotCurrupted === true) {


                    let detail = this.state.detail4;
                    detail.lettersOfEmployment = response.fileName;
                    this.swalServices.Success("File Uploaded");
                    this.setState({ detail4: detail }, () => {
                      this.validateField("lettersOfEmployment", "SupervisionExperience")
                    });
                  } else {
                    this.swalServices.Error("Invalid Document");
                  }
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLettersOfEmploymentSupervision: false });
              })
            }
            else {
              this.swalServices.Error("Please upload only PDF, DOC, DOCX files & Max of 5MB !");
            }
          }
          else if (fileType === "Letters_Of_Employment") {
            if (file && file.type === "application/pdf" || file.type === "application/pdf" ||
                file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword") {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.storagePath = "CONQUASMemberData\\LettersOfEmployment";
                this.setState({ isLettersOfEmployment: true });
                this.conquasMemberService.uploadPDF(uploadFile).then((response) => {
                    if (response != null) {
                        if (response.isNotCurrupted == true) {
                            let detail = this.state.employmentInformation;
                            detail.lettersOfEmployment = response.fileName;
                            this.swalServices.Success("File Uploaded");
                            this.setState({ detail2: detail }, () => {
                                this.validateField("lettersOfEmployment", "Employment_Information")
                            });
                        } else {
                            this.swalServices.Error("Invalid Document");
                        }
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLettersOfEmployment: false });
                })
            }
            else {
                this.swalServices.Error("Please upload only PDF, DOC, DOCX files & Max of 5MB !");
            }
        } 
        } else {
          this.swalServices.Error("File size must be less than 5 MB!");
        }
      }

    }


    else {

      this.swalServices
        .ErrorWithAction("Please login for upload documents!", "OK")
        .then((res) => {

        });
    }
  };

  saveCONQUASDocuments = (fileType, validationFieldName, SelectName) => {
    if (this.state.conquasMemberId > 0) {
      let request = {};
      request.columnName = fileType;
      request.conquasMemberId = this.state.conquasMemberId;
      request.fileName = "";
      if (fileType === "FullFacePhoto") {
        request.fileName =
          this.state.basicApplicationDetails.fullFacePhoto;
      }
      this.conquasMemberService.saveCONQUASDocuments(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("File Uploaded");
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isFullFacePhoto: false });
      });
    }
  };

  GetCONQUASMemberFiles = (type, Filepath) => {
    if (Filepath) {
      let request = [
        this.state.basicApplicationDetails.conquasMemberId,
        type,
        Filepath,
      ];
      this.conquasMemberService.GetCONQUASMemberFiles(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
          var filename = "";
          filename = `${type}${formattedDate}`;
          const extension = Filepath.split(".").pop();
          if (extension) {
            saveAs(new Blob([response.data]), `${filename}.${extension}`);
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {
      this.swalServices.Error("No data found");
    }
  };
  isValidateAllFields1 = () => {
    const newValidState = {
      isValid: this.state.clarification.trim() !== "",
      error: {
        clarification: this.state.clarification.trim() === "" ? "Field is required." : "",

      },
    };
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  // CONQUAS Member Validation Method Declaration Section Start
  validateField = (key, section) => {
    if (section === "Basic_Application") {
      const newValidState = validate(
        key,
        this.state.basicApplicationDetails,
        CONQUASMemberValidationRules,
        this.state.validStateCONQUASMember
      );
      this.setState({ validStateCONQUASMember: newValidState });
    }
    else if (section === "Education_Information") {
      const newValidState = validate(
        key,
        this.state.detail1,
        CONQUASAcademicQualificationsValidationRules,
        this.state.validStateCONQUASAcademicQualifications
      );
      this.setState({ validStateCONQUASAcademicQualifications: newValidState });
    }
    else if (section === "Educational_Information_Workshop") {
      const newValidState = validate(
        key,
        this.state.detail2,
        CONQUASTrainingWorkshopValidationRules,
        this.state.validStateCONQUASTrainingWorkshop
      );
      this.setState({ validStateCONQUASTrainingWorkshop: newValidState });
    }
    else if (section === "Educational_Information_GIP_Workshop") {
      const newValidState = validate(
        key,
        this.state.detail5,
        CONQUASGIPWorkshopValidationRules,
        this.state.validStateCONQUASGIPWorkshop
      );
      this.setState({ validStateCONQUASGIPWorkshop: newValidState });
    }
    else if (section === "SupervisionExperience") {
      const newValidState = validate(
        key,
        this.state.detail4,
        CONQUASSupervisionExperienceValidationRules,
        this.state.validStateCONQUASSupervisionExperience
      );
      this.setState({ validStateCONQUASSupervisionExperience: newValidState });
    }

  };

  isValidateAllFields = (section) => {

    if (section === "Basic_Application") {
      const newValidState = isValidForm(
        this.state.basicApplicationDetails,
        CONQUASMemberValidationRules,
        this.state.validStateCONQUASMember
      );
      this.setState({ validStateCONQUASMember: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Education_Information") {
      const newValidState = isValidForm(
        this.state.detail1,
        CONQUASAcademicQualificationsValidationRules,
        this.state.validStateCONQUASAcademicQualifications
      );
      this.setState({ validStateCONQUASAcademicQualifications: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Educational_Information_Workshop") {
      const newValidState = isValidForm(
        this.state.detail2,
        CONQUASTrainingWorkshopValidationRules,
        this.state.validStateCONQUASTrainingWorkshop
      );
      this.setState({ validStateCONQUASTrainingWorkshop: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Educational_Information_GIP_Workshop") {
      const newValidState = isValidForm(
        this.state.detail5,
        CONQUASGIPWorkshopValidationRules,
        this.state.validStateCONQUASGIPWorkshop
      );
      this.setState({ validStateCONQUASGIPWorkshop: newValidState });
      return newValidState.isValid;
    }
    else if (section === "SupervisionExperience") {
      const newValidState = isValidForm(
        this.state.detail4,
        CONQUASSupervisionExperienceValidationRules,
        this.state.validStateCONQUASSupervisionExperience
      );
      this.setState({ validStateCONQUASSupervisionExperience: newValidState });
      return newValidState.isValid;
    }
  };

  resetHandler = (Section) => {
    if (Section === "EducationalInfo1") {
      let reset1 = this.state.detail1;
      reset1.nameOfInstitution = "";
      reset1.academicQualificationsAndCourseCertificate = "";
      reset1.courseDurationId = 0;
      reset1.academicProfessionalQualificationsId = 0;
      reset1.academicProfessionalQualificationsLabel = 0;
      reset1.academicProfessionalQualificationsName = "";
      reset1.courseDurationLabel = "";
      reset1.durationOfCourseStartDate = "";
      reset1.durationOfCourseEndDate = "";
      reset1.academicProfessionalQualificationsIndustrialTrainingId = 0;
      this.setState({ detail1: reset1 });
    }
    else if (Section === "EducationalInfo2") {
      let reset2 = this.state.detail2;
      reset2.nameOfInstitutionForTrainingWorkshop = "";
      reset2.workshopTitle = "";
      reset2.courseDurationId = 0;
      reset2.courseDurationLabel = "";
      reset2.conquasTrainingWorkshopForId = 0;
      reset2.cONQUASTrainingWorkshopForLabel = "";
      reset2.conquasTrainingWorkshopForName = "";
      reset2.durationOfCourseStartDate = "";
      reset2.durationOfcourseEndDate = "";
      reset2.academicQualificationAndCourseCertificates = "";
      reset2.conquasTrainingWorkshopId = 0;
      this.setState({ detail1: reset2 });
    }
    else if (Section === "EducationalInfo3") {
      let reset3 = this.state.detail5;
      reset3.nameOfInstitution = "";
      reset3.workshopTitle = "";
      reset3.courseDurationId = 0;
      reset3.courseDurationLabel = "";
      reset3.conquasgipWorkshopForId = 0;
      reset3.cONQUASGIPWorkshopForLabel = "";
      reset3.conquasGIPWorkshopDropdownList = "";
      reset3.durationOfCourseStartDate = "";
      reset3.durationOfcourseEndDate = "";
      reset3.conquasgipWorkshopForName = "";
      reset3.gipWorkshopForId = "";
      reset3.academicQualificationsAndCourseCertificate = "";
      reset3.educationalProfessionalQualificationsGIPWorkshopId = 0;
      this.setState({ detail5: reset3 });
    }
    else if (Section === "EmploymentInfo2") {
      let reset4 = this.state.detail4;
      reset4.nameOfProject = "";
      reset4.costOfProject = "";
      reset4.titleOfPosition = "";
      reset4.dateFrom = "";
      reset4.dateTo = "";
      reset4.nameOfEmployer = "";
      reset4.addressOfEmployer = "";
      reset4.conquasScoreOfProject = "";
      reset4.academicQualificationsAndCourseCertificates = "";
      reset4.lettersOfEmployment = "";
      reset4.isQMProject = true;
      reset4.descriptionOfProject = "";
      reset4.conquasSupervisionExperienceId = 0;
      this.setState({ detail4: reset4 });
    }
  }

  handleAddRemoveEducationalInfo = (action, identity, key) => {
    debugger
    if (identity === "EducationalInfo1") {

      let eduDet1 = { ...this.state.detail1 };
      let eduInfo1 = this.state.educationalInformation;
      if (action === "HandleAdd") {
        var existdata1 = eduInfo1.educationalInformation1.filter(item => item.academicProfessionalQualificationsId === eduDet1.academicProfessionalQualificationsId)
        var existpkData1 = eduInfo1.educationalInformation1.filter(item => item.academicProfessionalQualificationsIndustrialTrainingId === eduDet1.academicProfessionalQualificationsIndustrialTrainingId && item.academicProfessionalQualificationsId === eduDet1.academicProfessionalQualificationsId)

        if (existdata1.length === 0 || existpkData1.length !== 0) {

          if (this.isValidateAllFields("Education_Information")) {

            this.AddEditConquasAcademicQualifications(eduDet1)
            this.resetHandler("EducationalInfo1");

          }
        }
        else {
          this.swalServices.Warning("Academic/Professional Qualifications is already exists");

        }

      }
      else if (action === "HandleRemove") {

        eduInfo1.educationalInformation1.splice(key, 1);

        this.setState({ educationalInformation: eduInfo1 });
      }
    }
    else if (identity === "EducationalInfo2") {

      let eduDet2 = { ...this.state.detail2 };
      let eduInfo2 = this.state.educationalInformation;
      if (action === "HandleAdd") {
        var existdata2 = eduInfo2.educationalInformation2.filter(item => item.conquasTrainingWorkshopForId === eduDet2.conquasTrainingWorkshopForId)
        var existpkData2 = eduInfo2.educationalInformation2.filter(item => item.conquasTrainingWorkshopId === eduDet2.conquasTrainingWorkshopId && item.conquasTrainingWorkshopForId === eduDet2.conquasTrainingWorkshopForId)

        if (existdata2.length === 0 || existpkData2.length !== 0) {
          if (this.isValidateAllFields("Educational_Information_Workshop")) {

            this.AddEditConquasTrainingWorkshop(eduDet2)
            this.resetHandler("EducationalInfo2");

          }
        }
        else {
          this.swalServices.Warning("CONQUAS Training Workshop For is already exists");

        }

      }
      else if (action === "HandleRemove") {
        eduInfo2.educationalInformation2.splice(key, 1);
      }
      this.setState({ educationalInformation: eduInfo2 });
    }

    else if (identity === "EducationalInfo3") {

      let eduDet5 = { ...this.state.detail5 };
      let eduInfo5 = this.state.educationalInformation;
      if (action === "HandleAdd") {

        if (this.isValidateAllFields("Educational_Information_GIP_Workshop")) {
          var existdata3 = eduInfo5.educationalInformation3.filter(item => item.conquasgipWorkshopForId === eduDet5.conquasgipWorkshopForId)
          var existpkData3 = eduInfo5.educationalInformation3.filter(item => item.educationalProfessionalQualificationsGIPWorkshopId === eduDet5.educationalProfessionalQualificationsGIPWorkshopId && item.conquasgipWorkshopForId === eduDet5.conquasgipWorkshopForId)

          if (existdata3.length === 0 || existpkData3 !== 0) {
            this.AddEditConquasTrainingGIPWorkshop(eduDet5)

            this.resetHandler("EducationalInfo3");
          }
          else {
            this.swalServices.Warning("Good Industry Practices (GIP) Guide Workshops is already exists");

          }
        }



      }
      else if (action === "HandleRemove") {
        eduInfo5.educationalInformation3.splice(key, 1);
      }
      this.setState({ educationalInformation: eduInfo5 }, () => {

        this.gipWorkshopCalculation();

      });
    }
    else if (identity === "EmploymentInfo1") {
      let eduDet3 = { ...this.state.detail3 };
      let eduInfo3 = this.state.employmentInformation;
      if (action === "HandleAdd") {
        if (this.isValidateAllFields("Employment_Information")) {
          eduInfo3.currentEmploymentInfo.push(eduDet3);
          this.resetHandler("EmploymentInfo1");
        }
      }
      else if (action === "HandleRemove") {
        eduInfo3.currentEmploymentInfo.splice(key, 1);
      }
      this.setState({ employmentInformation: eduInfo3 });
    }
    else if (identity === "EmploymentInfo2") {
      let eduDet4 = { ...this.state.detail4 };
      let eduInfo4 = this.state.conquasSupervisonExperienceList;
      if (action === "HandleAdd") {
        if (this.isValidateAllFields("SupervisionExperience")) {

          this.AddEditSupervisionExperience(eduDet4)
          this.resetHandler("EmploymentInfo2");
          this.setState({ conquasSupervisonExperienceList: eduInfo4 });
        }
      }
      else if (action === "HandleRemove") {
        eduInfo4.conquasSupervisonExperienceList.splice(key, 1);
      }
      this.setState({ conquasSupervisonExperienceList: eduInfo4 });
    }
  }






  validationForSupervisorLevel = () => {

    let Education_Information = this.state.educationalInformation;

    let ErrorMessage = this.state.validationRuleForCONQUASSupervisorAndManager;

    let validationRulesForSAndM = this.state.rulesForCONQUASSupervisorAndManager;

    let validationRulesForQuality = this.state.rulesForQualitySupervisorAndManager;
    if (ErrorMessage.isQualityMark === false) {
      ErrorMessage.requiredToSelected.map((x) => {
        let haveOrNot = Education_Information.educationalInformation3.filter((y) => y.conquasgipWorkshopForId === x);
        if (haveOrNot.length > 0) {
          validationRulesForSAndM.isWaterproofingToInternalWetAreasSelected = true;
        } else {
          validationRulesForSAndM.isWaterproofingToInternalWetAreasSelected = false;
        }
        return x;
      });
      if (ErrorMessage.RequiredPoints === this.state.cONQUASSupervisorAndManagerPoints || this.state.cONQUASSupervisorAndManagerPoints > ErrorMessage.RequiredPoints) {
        validationRulesForSAndM.isMiniAchievePoints = true;
      } else {
        validationRulesForSAndM.isMiniAchievePoints = false;
      }

      if (validationRulesForSAndM.isWaterproofingToInternalWetAreasSelected === true && validationRulesForSAndM.isMiniAchievePoints === true && ErrorMessage.RequiredPoints <= this.state.cONQUASSupervisorAndManagerPoints) {
        this.setState({ isValidateGIPCalculation: true });
      } else {
        this.setState({ isValidateGIPCalculation: false });
      }
      this.setState({ rulesForCONQUASSupervisorAndManager: validationRulesForSAndM });
    }
    //else 
    if (ErrorMessage.isQualityMark === true) {

      ErrorMessage.requiredToSelected.map((x) => {

        let haveOrNot = Education_Information.educationalInformation3.filter((y) => y.conquasgipWorkshopForId === x);
        if (x === 1) {
          if (haveOrNot.length > 0) {
            validationRulesForQuality.isWaterproofingToInternalWetAreasSelected = true;
          } else {
            validationRulesForQuality.isWaterproofingToInternalWetAreasSelected = false;
          }
        }

        if (x === 2) {
          if (haveOrNot.length > 0) {
            validationRulesForQuality.isMarbleGraniteCeremicSelected = true;
          } else {
            validationRulesForQuality.isMarbleGraniteCeremicSelected = false;
          }
        }

        if (x === 3) {
          if (haveOrNot.length > 0) {
            validationRulesForQuality.isAluminiumWindowsSelected = true;
          } else {
            validationRulesForQuality.isAluminiumWindowsSelected = false;
          }
        }

        if (x === 4) {
          if (haveOrNot.length > 0) {
            validationRulesForQuality.isTimberFlooringSelected = true;
          } else {
            validationRulesForQuality.isTimberFlooringSelected = false;
          }
        }
        return x;
      });


      if (ErrorMessage.RequiredPoints === this.state.cONQUASSupervisorAndManagerPoints || this.state.cONQUASSupervisorAndManagerPoints > ErrorMessage.RequiredPoints) {
        validationRulesForQuality.isMiniAchievePoints = true;
      } else {
        validationRulesForQuality.isMiniAchievePoints = false;
      }

      if (validationRulesForQuality.isWaterproofingToInternalWetAreasSelected === true
        && validationRulesForQuality.isMarbleGraniteCeremicSelected === true
        && validationRulesForQuality.isAluminiumWindowsSelected === true
        && validationRulesForQuality.isMiniAchievePoints === true
        && ErrorMessage.RequiredPoints <= this.state.cONQUASSupervisorAndManagerPoints) {
        this.setState({ isValidateGIPCalculation: true });
      } else {
        this.setState({ isValidateGIPCalculation: false });
      }
      this.setState({ rulesForQualitySupervisorAndManager: validationRulesForQuality });
    }

    ////    [ sanjay :- old calculation ]
    //
    // let Education_Information = this.state.educationalInformation;
    // let gipWorkshopList = this.state.gipWorkshopList;
    // //Education_Information.educationalInformation3
    // let ErrorMessage = this.state.validationRuleForCONQUASSupervisorAndManager;
    // let ErrorMsg = "";

    // if (ErrorMessage.isQualityMark === false) {
    //     ErrorMessage.requiredToSelected.map((x) => {
    //         let haveOrNot = Education_Information.educationalInformation3.filter((y) => y.conquasgipWorkshopForId === x);
    //         if (haveOrNot.length > 0) {
    //             ErrorMessage.isValid = true;
    //             ErrorMessage.error = {}
    //         } else {

    //             ErrorMessage.isValid = false;
    //             ErrorMessage.error = { ruleForCONQUASSupervisorAndManager: "Waterproofing To Internal Wet Areas is a compulsory requirement" }
    //         }
    //     })
    // } else if (ErrorMessage.isQualityMark === true) {
    //     let isValid = true;
    //     ErrorMessage.requiredToSelected.map((x, key) => {

    //         let haveOrNot = Education_Information.educationalInformation3.filter((y) => y.conquasgipWorkshopForId === x);
    //         if (haveOrNot.length > 0) {
    //             ErrorMessage.isValid = true;

    //             //ErrorMessage.error = {}
    //         } else {
    //             ErrorMessage.isValid = false;
    //             isValid = false;
    //             let label = gipWorkshopList.filter((a) => a.value === x)[0].label;
    //             if (ErrorMsg === '') {
    //                 ErrorMsg = `${label}`
    //             } else {
    //                 ErrorMsg = ErrorMsg + ", " + label;
    //             }
    //         }
    //     })
    //     if (isValid === false) {
    //         ErrorMessage.isValid = false;
    //     }
    //     ErrorMessage.error = { ruleForCONQUASSupervisorAndManager: `${ErrorMsg} is a compulsory requirement` }
    // }

    // this.setState({ validationRuleForCONQUASSupervisorAndManager: ErrorMessage })

    ////    [ sanjay :- old calculation ]
  }
  editCONQUASMemberDetails = () => {

    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_update_conquasMemebr_Information.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {

          if (this.isValidCONQUASMember()) {
            let request = this.state.basicApplicationDetails;
            request.updatedAppId = this.state.updatedAppId;
            request.updatedBy = this.state.updatedBy;
            this.setState({ isLoading: true });
            this.conquasMemberService
              .EditCONQUASMemberDetails(request)
              .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  this.swalServices.Success(
                    "CONQUAS Member Details Updated successfully"
                  );

                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              });
          }
        }
      });
  };

  updateCONQUASPersonalParticularDetail = () => {

    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_update_conquasMemebr_Information.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {


          let request = {};
          let Data = this.state.basicApplicationDetails
          request.createdAppId = this.state.updatedAppId;
          request.createdBy = this.state.updatedBy;
          request.conquasMemberId = Data.conquasMemberId;
          request.firstName = Data.firstName;
          request.lastName = Data.lastName;
          request.dateOfBirth = Data.dateOfBirth;
          request.identificationNo = Data.identificationNo;
          request.isSexMale = Data.isSexMale;
          request.homeAddressLine1 = Data.homeAddressLine1;
          request.homeAddressLine2 = Data.homeAddressLine2;
          request.homeAddressLine3 = Data.homeAddressLine3;
          request.postalCode = Data.postalCode;
          request.countryId = Data.countryId;
          request.contactNumberHome = Data.contactNumberHome;
          request.contactNumberOffice = Data.contactNumberOffice;
          request.mobileNumber = Data.mobileNumber;
          request.email = Data.email;
          request.countryOfBirth = Data.countryOfBirth;
          request.conquasNationalityId = Data.conquasNationalityId;
          request.fullFacePhoto = Data.fullFacePhoto;
          request.nameOnCertificate = Data.nameOnCertificate;
          this.setState({ isLoading: true });
          this.conquasMemberService
            .updateCONQUASPersonalParticularDetail(request)
            .then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                if (response.responseItem.responseContent.errorColumn === 'Email') {
                  this.swalServices.Warning("Email is already exists");
                } else {
                  this.swalServices.Success(
                    "CONQUAS Personal Particular Details Updated successfully"
                  );
                }
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
          //}
        }
      });
  };

  isValidCONQUASMember = () => {
    let data = { ...CONQUASMemberValidationRules };
    const returnValidState = isValidForm(
      this.state.basicApplicationDetails,
      data,
      this.state.validStateCONQUASMember
    );
    this.setState({ validStateCONQUASMember: returnValidState });
    return returnValidState.isValid;
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  handleChange = (event, identity) => {

    let value = event.target.value.toUpperCase();
    let name = event.target.name;
    if (identity === "CONQUASBasicApplication") {
      let basicApplicationDetails = { ...this.state.basicApplicationDetails };
      basicApplicationDetails[name] = value;
      this.setState({ basicApplicationDetails: basicApplicationDetails });

    } else if (identity === "Conquas_Academic_Qualifications") {
      let detail1 = this.state.detail1;
      detail1[name] = value;
      this.setState({ detail1: detail1 });
    } else if (identity === "Conquas_Training_Workshop") {
      let detail2 = this.state.detail2;
      if (name === "nameOfInstitution") {
        name = "nameOfInstitutionForTrainingWorkshop";
      }
      detail2[name] = value;
      this.setState({ detail2: detail2 });
    } else if (identity === "Conquas_GIP_Workshop") {
      let detail5 = this.state.detail5;
      detail5[name] = value;
      this.setState({ detail5: detail5 });
    } else if (identity === "CONQUASTrainingInformation") {
      let employmentInformation = this.state.employmentInformation;
      employmentInformation[name] = value;
      this.setState({ employmentInformation: employmentInformation });
    } else if (identity === "Supervision_Experience") {
      let detail4 = this.state.detail4;
      detail4[name] = value;
      this.setState({ detail4: detail4 });
    } else if (identity === "Supervision_Experience_Radio") {
      let detail4 = { ...this.state.detail4 };
      detail4.isQMProject = !detail4.isQMProject;
      this.setState({ detail4: detail4 });
    }
  };

  handleRadioButtons = (event, identity) => {
    let detail = { ...this.state.basicApplicationDetails };
    detail.isSexMale = !detail.isSexMale;
    this.setState({ basicApplicationDetails: detail });

    let detail2 = { ...this.state.detail4 };
    detail2.isQMProject = !detail2.isQMProject;
    this.setState({ detail4: detail2 });

  };

  // Handle Change Method For Dropdown Select
  handleChangeInDropdown = (selected, identity) => {
    if (identity === "Country") {
      let detail = { ...this.state.basicApplicationDetails };
      if (selected.value === "*") {
        detail.countryId = 0;
      } else {
        detail.countryId = selected.value;
      }
      this.setState({ basicApplicationDetails: detail }, () => {

      });
    } else if (identity === "CountryOfBirth") {
      let detail = { ...this.state.basicApplicationDetails };
      if (selected.value === "*") {
        detail.countryOfBirth = 0;
      } else {
        detail.countryOfBirth = selected.value;
      }
      this.setState({ basicApplicationDetails: detail }, () => {

      });
    } else if (identity === "Nationality") {
      let detail = { ...this.state.basicApplicationDetails };
      if (selected.value === "*") {
        detail.conquasNationalityId = 0;
      } else {
        detail.conquasNationalityId = selected.value;
      }
      this.setState({ basicApplicationDetails: detail }, () => {

      });
    } else if (identity === "Course_Duration") {
      let detail = { ...this.state.detail1 };
      if (selected.value === "*") {
        detail.academicProfessionalQualificationsId = 0;
      } else {
        detail.academicProfessionalQualificationsId = selected.value;
        detail.academicProfessionalQualificationsName = selected.label;
      }
      this.setState({ detail1: detail }, () => {

      });
    } else if (identity === "Training_Workshop") {
      let detail = { ...this.state.detail2 };
      if (selected.value === "*") {
        detail.conquasTrainingWorkshopForId = 0;
      } else {
        detail.conquasTrainingWorkshopForId = selected.value;
        detail.conquasTrainingWorkshopForName = selected.label;
      }
      this.setState({ detail2: detail }, () => {

      });
    } else if (identity === "GIP_Workshop") {
      let detail = { ...this.state.detail5 };
      if (selected === "*") {
        detail.conquasgipWorkshopForId = 0;
      } else {
        detail.conquasgipWorkshopForId = selected.value;
        detail.conquasgipWorkshopForName = selected.label;
      }
      this.setState({ detail5: detail }, () => {
      });

    };
  }

  saveClarificationDetails = () => {

    let isValidInput = this.isValidateAllFields1();
    if (isValidInput) {
      this.setState({ setSendClarifications: true });
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_SendClarifications.replace(
            "{0}",
            "Admin"
          ),
          "",
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        ).then((response) => {
          if (response) {
            let request = {
              conquasMemberId: this.state.basicApplicationDetails.conquasMemberId,
              clarificationDetails: this.state.clarification,
              updatedBy: this.state.updatedBy,
              updatedAppId: this.state.updatedAppId
            };
            this.setState({ isLoading: true });
            this.conquasMemberService
              .SaveConquasMemberClarificationDetails(request)
              .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  this.swalServices.Success("Clarification mail sent successfully");
                  this.setState({ setSendClarifications: false });
                  this.setState({ clarification: "" });
                  this.getCONQUASMemberDetailByMemberId(this.state.conquasMemberId);
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              });
          } else {
            this.setState({ isLoading: false });
            this.setState({ setSendClarifications: false });
          }
        });
    }
  };

  handleChangeClarificationDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        clarification: data,
      });
    }
  };

  handleChangeLOAetails = (data) => {
    if (typeof data === "string") {
      let basicApplication = this.state.basicApplicationDetails;
      basicApplication.loa = data;
      this.setState({
        basicApplicationDetails: basicApplication
      });
    }
  };

  setSendClarificationsFlag = () => {
    this.setState({ setSendClarifications: true });
  };

  setLOAFlag = () => {

    this.setState({ setUpdateLOA: true });
  };


  setRejectionReasonFlag = () => {
    this.setState({ setRejectionReason: true });
  };

  rejectCONQUASMemberApplication = (flag) => {

    let reject = this.state.sendRejectionReason;
    let rejectReason = "";
    if (flag !== "UnReject") {
      rejectReason = reject;//reject.level.content;
    }
    if ((rejectReason !== null && rejectReason !== "") || flag === "UnReject") {
      let request = {};
      request.updatedBy = this.state.updatedBy;
      request.updatedAppId = this.state.updatedAppId;
      request.conquasMemberId = this.state.basicApplicationDetails.conquasMemberId;
      request.sendRejectionReason = rejectReason;
      if (flag === "Rejected") {
        request.isRejected = true;
      } else if (flag === "UnReject") {
        request.isRejected = false;
      }
      this.setState({ isLoadingRejectAplication: true });



      this.swalServices
        .Confirm(
          request.isRejected === false ? CommonSuccessMessages.Confirm_Appeal_Process.replace(
            "{0}",
            "Admin"
          ) : CommonSuccessMessages.Confirm_Reject_Application.replace(
            "{0}",
            "Admin"
          ),
          "",
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        ).then((response) => {
          if (response) {
            this.conquasMemberService
              .rejectCONQUASMemberApplication(request)
              .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  this.swalServices.Success(`Conquas Member ${flag} successfully`);
                  this.setState({ route: "/Membership/CONQUASRegistration" });
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoadingRejectAplication: false });
              });
          } else {
            this.setState({ isLoadingRejectAplication: false });

          }

        });

    } else {
      this.swalServices.Error("Please enter detail");
    }
  };

  handleChangeSendRejectionReasonDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        sendRejectionReason: data,
      });
    }
    this.setState({ sendRejectionReason: data });
  };

  AddEditConquasAcademicQualifications = (request) => {
    request.updatedAppId = this.state.updatedAppId;
    request.updatedBy = this.state.updatedBy;
    request.conquasMemberId = this.state.conquasMemberId;
    this.conquasMemberService.AddEditConquasAcademicQualifications(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.getEducationInformationList()
        this.swalServices.Success(
          "Academic Qualifications Added Successfully."
        );
      } else {
        this.swalServices.Error(response.message);
      }
    })
  }

  getEducationInformationList = () => {
    if (this.state.conquasMemberId > 0) {
      this.conquasMemberService.getEducationInformationList(this.state.conquasMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let eduInfo1 = this.state.educationalInformation;
          eduInfo1.educationalInformation1 = response.responseItem.responseContent;
          this.setState({ educationalInformation: eduInfo1 }, () => {
            this.gipWorkshopCalculation();
          })
        } else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }

  getEducationInformationCONQUASTrainingList = () => {
    if (this.state.conquasMemberId > 0) {
      this.conquasMemberService.getEducationInformationCONQUASTrainingList(this.state.conquasMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let eduInfo2 = this.state.educationalInformation;
          eduInfo2.educationalInformation2 = response.responseItem.responseContent;
          this.setState({ educationalInformation: eduInfo2 }, () => {
            this.gipWorkshopCalculation();
          })
        } else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }

  getIndividualEducationInformationCONQUASGIPWorkshopList = () => {
    if (this.state.conquasMemberId > 0) {
      this.conquasMemberService.getIndividualEducationInformationCONQUASGIPWorkshopList(this.state.conquasMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let eduInfo3 = this.state.educationalInformation;
          eduInfo3.educationalInformation3 = response.responseItem.responseContent;
          this.setState({ educationalInformation: eduInfo3 }, () => {
            this.gipWorkshopCalculation();
          })
        } else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }

  getSupervisionExperienceList = () => {

    if (this.state.conquasMemberId > 0) {
      this.conquasMemberService.getSupervisionExperienceList(this.state.conquasMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let eduInfo2 = this.state.conquasSupervisonExperienceList;
          eduInfo2 = response.responseItem.responseContent;
          this.setState({ conquasSupervisonExperienceList: eduInfo2 }, () => {
            this.gipWorkshopCalculation();
          })
        } else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }

  deleteSupervisionExperience = (value) => {

    let request = {
      id: value,
      deletedBy: this.state.updatedBy,
      appId: this.state.updatedAppId
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.conquasMemberService.deleteSupervisionExperience(request).then((response) => {
            if (response.statusCode === 200) {

              this.swalServices.Success("Deleted");
              this.getSupervisionExperienceList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  }

  AddEditConquasTrainingWorkshop = (request) => {
    request.UpdatedAppId = this.state.updatedAppId;
    request.updatedBy = this.state.updatedBy;
    request.conquasMemberId = this.state.conquasMemberId;
    this.conquasMemberService.AddEditConquasTrainingWorkshop(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem !== null) {

        this.getEducationInformationCONQUASTrainingList();
        this.swalServices.Success(
          "CONQUAS Training Workshop Added Successfully."
        );

      } else {
        this.swalServices.Error(response.message);
      }
    })
  }

  AddEditConquasTrainingGIPWorkshop = (request) => {
    request.UpdatedAppId = this.state.updatedAppId;
    request.updatedBy = this.state.updatedBy;
    request.conquasMemberId = this.state.conquasMemberId;
    this.conquasMemberService.AddEditConquasTrainingGIPWorkshop(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.getIndividualEducationInformationCONQUASGIPWorkshopList();
        this.swalServices.Success(
          "CONQUAS Training GIP Workshop Added Successfully."
        );
      } else {
        this.swalServices.Error(response.message);
      }
    })
  }


  deleteConquasAcademicQualifications = (value) => {

    let request = {
      id: value,
      deletedBy: this.state.updatedBy,
      appId: this.state.updatedAppId
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.conquasMemberService.deleteConquasAcademicQualifications(request).then((response) => {
            if (response.statusCode === 200) {

              this.swalServices.Success("Deleted");
              this.getEducationInformationList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  }


  deleteConquasTrainingWorkshop = (value) => {

    let request = {
      id: value,
      deletedBy: this.state.updatedBy,
      appId: this.state.updatedAppId
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.conquasMemberService.deleteConquasTrainingWorkshop(request).then((response) => {
            if (response.statusCode === 200) {

              this.swalServices.Success("Deleted");
              this.getEducationInformationCONQUASTrainingList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  }

  deleteConquasTrainingGIPWorkshop = (value) => {

    let request = {
      id: value,
      deletedBy: this.state.updatedBy,
      appId: this.state.updatedAppId
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.conquasMemberService.deleteConquasTrainingGIPWorkshop(request).then((response) => {
            if (response.statusCode === 200) {

              this.swalServices.Success("Deleted");
              this.getIndividualEducationInformationCONQUASGIPWorkshopList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  }

  AddEditSupervisionExperience = (request) => {
    request.updatedAppId = this.state.updatedAppId;
    request.updatedBy = this.state.updatedBy;
    request.conquasMemberId = this.state.conquasMemberId;
    this.conquasMemberService.AddEditSupervisionExperience(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.getSupervisionExperienceList();
        this.swalServices.Success(
          "CONQUAS Supervision Experience Added Successfully."
        );
      } else {
        this.swalServices.Error(response.message);
      }
    })
  }

  saveMainImage = (event, file, imageIdentity) => {
    event.preventDefault();
    this.conquasMemberService.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("Photo Uploaded");
        if (file.storagePath === "CONQUASMemberData") {
          let detail = this.state.basicApplicationDetails;
          detail.fullFacePhoto = response.fileName;
          this.setState({ basicApplicationDetails: { ...detail } });
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  updateCONQUASMemberDetails = () => {
    if (this.state.conquasMemberId > 0) {
      let res = {};
      res.updatedAppId = this.state.updatedAppId;
      res.updatedBy = this.state.updatedBy;
      res.conquasMemberId = this.state.conquasMemberId;
      res.conquasLevelId = this.state.conquasLevelId;
      res.firstName = this.state.basicApplicationDetails.firstName;
      res.lastName = this.state.basicApplicationDetails.lastName;
      res.dateOfBirth = this.state.basicApplicationDetails.dateOfBirth;
      res.identificationNo = this.state.basicApplicationDetails.identificationNo;
      res.isSexMale = this.state.basicApplicationDetails.isSexMale;
      res.homeAddressLine1 = this.state.basicApplicationDetails.homeAddressLine1;
      res.homeAddressLine2 = this.state.basicApplicationDetails.homeAddressLine2;
      res.homeAddressLine3 = this.state.basicApplicationDetails.homeAddressLine3;
      res.countryId = this.state.basicApplicationDetails.countryId;
      res.postalCode = this.state.basicApplicationDetails.postalCode;
      res.contactNumberHome = this.state.basicApplicationDetails.contactNumberHome;
      res.contactNumberOffice = this.state.basicApplicationDetails.contactNumberOffice;
      res.mobileNumber = this.state.basicApplicationDetails.mobileNumber;
      res.email = this.state.basicApplicationDetails.email;
      res.countryOfBirth = this.state.basicApplicationDetails.countryOfBirth;
      res.conquasNationalityId = this.state.basicApplicationDetails.conquasNationalityId;
      res.nameOfCurrentEmployer = this.state.basicApplicationDetails.nameOfCurrentEmployer;
      res.officeAddress = this.state.basicApplicationDetails.officeAddress;
      res.officeAddressLine2 = this.state.basicApplicationDetails.officeAddressLine2;
      res.officeAddressLine3 = this.state.basicApplicationDetails.officeAddressLine3;
      res.designation = this.state.basicApplicationDetails.designation;
      res.dateJoinedCompany = this.state.basicApplicationDetails.dateJoinedCompany;
      this.setState({ isCONQUASBasicApplicationLoading: true });
      this.conquasMemberService
        .UpdateCONQUASMemberDetails(res)
        .then((response) => {
          if (response != null && response.statusCode === 200) {
            this.swalServices.Success("CONQUAS basic application detail save sucessfully.");
            this.setState({ isCONQUASBasicApplicationLoading: false });
          } else {
            this.swalServices.Error(response.message);
            this.setState({ isCONQUASBasicApplicationLoading: false });
          }
        });
    }
  }

  updateCONQUAsLOADetails = () => {
    this.setState({ setUpdateLOA: true });
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_LOA.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {
          let request = {
            conquasMemberId: this.state.basicApplicationDetails.conquasMemberId,
            loa: this.state.basicApplicationDetails.loa,
            firstName: this.state.basicApplicationDetails.firstName,
            lastName: this.state.basicApplicationDetails.lastName,
            email: this.state.basicApplicationDetails.email,
            updatedBy: this.state.updatedBy,
            updatedAppId: this.state.updatedAppId
          };
          this.setState({ isLoadingLOA: true });
          this.conquasMemberService
            .updateCONQUAsLOADetails(request)
            .then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("LOA notes save sucessfully.");
                this.setState({ setUpdateLOA: false }, () => {
                  this.getCONQUASMemberDetailByMemberId(this.state.conquasMemberId);
                });
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoadingLOA: false });
            });
        } else {
          this.setState({ isLoadingLOA: false });
          this.setState({ setUpdateLOA: false });
        }
      });
  }




  gipWorkshopCalculation = () => {
    
    let pointlist = this.state.gipWorkshopListForCounting;
    let Education_Information = this.state.educationalInformation;
    let validationRuleForSupervisorAndManager = this.state.validationRuleForCONQUASSupervisorAndManager;
    //let GIP_List = Education_Information.educationalInformation3;
    let countPoints = 0;

    ////    [ sanjay :- old calculation ]
    // Education_Information.educationalInformation3.map((x) => {

    //   countPoints = countPoints + pointlist.filter((y) => y.value === x.conquasgipWorkshopForId)[0].gipWorkshopPoints;
    //   return x;
    // })

    // kirtesh edits
    Education_Information.educationalInformation3.map((x) => {
      const matchedItem = pointlist.find((y) => y.value === x.conquasgipWorkshopForId);

      if (matchedItem) {
        countPoints += matchedItem.gipWorkshopPoints;
      } else {
        console.warn(`No matching point found for conquasgipWorkshopForId: ${x.conquasgipWorkshopForId}`);
      }

      return x;
    });
    //-k

    if (this.state.basicApplicationDetails.conquasLevelId === 4 || this.state.basicApplicationDetails.conquasLevelId === 1) {
      validationRuleForSupervisorAndManager.RequiredPoints = this.state.requredGIPMinimumPointsForSupervisorAndManager;
      validationRuleForSupervisorAndManager.requiredToSelected = this.state.requiredForCONQUASSupervisorAndManager;
      validationRuleForSupervisorAndManager.isQualityMark = false;
      validationRuleForSupervisorAndManager.rules = this.state.rulesForCONQUASSupervisorAndManager

    } else if (this.state.basicApplicationDetails.conquasLevelId === 2 || this.state.basicApplicationDetails.conquasLevelId === 5) {
      validationRuleForSupervisorAndManager.RequiredPoints = this.state.requredGIPMinimumPointsForQualityMarkSupervisorAndManager;
      validationRuleForSupervisorAndManager.requiredToSelected = this.state.requiredForQualityMarkSupervisorAndManager;
      validationRuleForSupervisorAndManager.isQualityMark = true;
      validationRuleForSupervisorAndManager.rules = this.state.rulesForQualitySupervisorAndManager;
    }

    this.setState({ validationRuleForCONQUASSupervisorAndManager: validationRuleForSupervisorAndManager, cONQUASSupervisorAndManagerPoints: countPoints }
      , () => {
        ////    [ sanjay :- old calculation ]
        //if (validationRuleForSupervisorAndManager.RequiredPoints === this.state.cONQUASSupervisorAndManagerPoints || this.state.cONQUASSupervisorAndManagerPoints > validationRuleForSupervisorAndManager.RequiredPoints) {
        //this.setState({ cONQUASSupervisorAndManagerPoints: countPoints })
        this.validationForSupervisorLevel();
        //}
        ////    [ sanjay :- old calculation ]
      });

  }





  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="custom-card shadow-lg bg-white">
            {/* <h2>{this.state.conquasMemberId}</h2> */}
            <div>
              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="Personal Particular"
              >
                <CONQUASBasicApplication
                  countryList={this.state.countryList}
                  levelList={this.state.levelList}
                  placeOfBirthList={this.state.placeOfBirthList}
                  nationalityList={this.state.nationalityList}
                  basicApplicationDetails={this.state.basicApplicationDetails}
                  validStateCONQUASMember={this.state.validStateCONQUASMember}
                  isDisabledFlag={this.state.isDisabledFlag}
                  handleChange={this.handleChange.bind(this)}
                  handleRadioButtons={this.handleRadioButtons.bind(this)}
                  handleChangeInDropdown={this.handleChangeInDropdown.bind(
                    this
                  )}
                  validateField={this.validateField.bind(this)}
                  updateCONQUASMemberDetails={this.updateCONQUASMemberDetails.bind(this)}
                  handleAddRemoveEducationalInfo={this.handleAddRemoveEducationalInfo.bind(this)}
                  isCONQUASBasicApplicationLoading={this.state.isCONQUASBasicApplicationLoading}
                  GetCONQUASMemberFiles={this.GetCONQUASMemberFiles.bind(this)}
                  isFullFacePhoto={this.state.isFullFacePhoto}
                  onFileChange={this.onFileChange.bind(this)}
                  saveMainImage={this.saveMainImage.bind(this)}
                  isLoading={this.state.isLoading}
                  updateCONQUASPersonalParticularDetail={this.updateCONQUASPersonalParticularDetail.bind(this)}
                />
              </SectionWithBorder>
            </div>

            <div>
              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="Educational & Professional Qualifications"
              >
                <CONQUASQualificationsProfile
                  detail1={this.state.detail1}
                  detail2={this.state.detail2}
                  detail4={this.state.detail4}
                  detail5={this.state.detail5}
                  educationInformationList={this.state.educationInformationList}
                  educationInformationCONQUASTrainingList={this.state.educationInformationCONQUASTrainingList}
                  educationInformationCONQUASGIPWorkshopList={this.state.educationInformationCONQUASGIPWorkshopList}
                  academicQualificationList={this.state.academicQualificationList}
                  courseDurationList={this.state.courseDurationList}
                  conquasGIPWorkshopDropdownList={this.state.conquasGIPWorkshopDropdownList}
                  trainingWorkshopList={this.state.trainingWorkshopList}
                  gipWorkshopList={this.state.gipWorkshopList}
                  educationalInformation={this.state.educationalInformation}
                  validStateCONQUASAcademicQualifications={this.state.validStateCONQUASAcademicQualifications}
                  validStateCONQUASTrainingWorkshop={this.state.validStateCONQUASTrainingWorkshop}
                  validStateCONQUASGIPWorkshop={this.state.validStateCONQUASGIPWorkshop}
                  cONQUASSupervisorAndManagerPoints={this.state.cONQUASSupervisorAndManagerPoints}
                  validationRuleForCONQUASSupervisorAndManager={this.state.validationRuleForCONQUASSupervisorAndManager}
                  isDisabledFlag={this.state.isDisabledFlag}
                  handleChange={this.handleChange.bind(this)}
                  handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
                  validateField={this.validateField.bind(this)}
                  updateCONQUASMemberDetails={this.updateCONQUASMemberDetails.bind(this)}
                  handleAddRemoveEducationalInfo={this.handleAddRemoveEducationalInfo.bind(this)}
                  getCONQUASMemberDetailByMemberId={this.getCONQUASMemberDetailByMemberId.bind(this)}
                  getAcademicQualifications={this.getAcademicQualifications.bind(this)}
                  Academic_ProfessionalQualificationsDropDown={this.Academic_ProfessionalQualificationsDropDown.bind(this)}
                  getTrainingWorkshop={this.getTrainingWorkshop.bind(this)}
                  getTrainingGIPWorkshop={this.getTrainingGIPWorkshop.bind(this)}
                  onFileChange={this.onFileChange.bind(this)}
                  isCONQUASBasicApplicationLoading={this.state.isCONQUASBasicApplicationLoading}
                  isUploadAcademicQualifications={this.state.isUploadAcademicQualifications}
                  isUploadAcademicQualificationsConquasTraining={this.state.isUploadAcademicQualificationsConquasTraining}
                  isUploadAcademicQualificationsConquasGIP={this.state.isUploadAcademicQualificationsConquasGIP}
                  GetCONQUASMemberFiles={this.GetCONQUASMemberFiles.bind(this)}
                  supervisionexperience={this.state.supervisionexperience}
                  isAcademicQualificationsAndCourseCertificates={this.state.isAcademicQualificationsAndCourseCertificates}
                  isLettersOfEmployment={this.state.isLettersOfEmployment}
                  deleteConquasAcademicQualifications={this.deleteConquasAcademicQualifications.bind(this)}
                  deleteConquasTrainingWorkshop={this.deleteConquasTrainingWorkshop.bind(this)}
                  deleteConquasTrainingGIPWorkshop={this.deleteConquasTrainingGIPWorkshop.bind(this)}
                  deleteSupervisionExperience={this.deleteSupervisionExperience.bind(this)}
                  // new GIP 
                  rulesForCONQUASSupervisorAndManager={this.state.rulesForCONQUASSupervisorAndManager}
                  rulesForQualitySupervisorAndManager={this.state.rulesForQualitySupervisorAndManager}
                  basicApplicationDetails={this.state.basicApplicationDetails}
                  isValidateGIPCalculation={this.state.isValidateGIPCalculation}


                />
              </SectionWithBorder>
            </div>

            <div>
              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="Employment & Project Information"
              >
                <CONQUASTrainingInformation
                  countryList={this.state.countryList}
                  employmentInformation={this.state.employmentInformation}
                  detail4={this.state.detail4}
                  detail5={this.state.detail5}
                  conquasSupervisonExperienceList={this.state.conquasSupervisonExperienceList}
                  validStateCONQUASMember={this.state.validStateCONQUASMember}
                  basicApplicationDetails={this.state.basicApplicationDetails}
                  isDisabledFlag={this.state.isDisabledFlag}
                  handleChange={this.handleChange.bind(this)}
                  handleChangeInDropdown={this.handleChangeInDropdown.bind(
                    this
                  )}
                  validateField={this.validateField.bind(this)}
                  updateCONQUASMemberDetails={this.updateCONQUASMemberDetails.bind(this)}
                  handleAddRemoveEducationalInfo={this.handleAddRemoveEducationalInfo.bind(this)}
                  isCONQUASBasicApplicationLoading={this.state.isCONQUASBasicApplicationLoading}
                  isSupervisionExperienceAndCourseCertificates={this.state.isSupervisionExperienceAndCourseCertificates}
                  isLettersOfEmploymentSupervision={this.state.isLettersOfEmploymentSupervision}
                  GetCONQUASMemberFiles={this.GetCONQUASMemberFiles.bind(this)}
                  onFileChange={this.onFileChange.bind(this)}
                  getSupervisionExperience={this.getSupervisionExperience.bind(this)}
                  supervisionexperience={this.state.supervisionexperience}
                  validStateCONQUASSupervisionExperience={this.state.validStateCONQUASSupervisionExperience}
                  deleteSupervisionExperience={this.deleteSupervisionExperience.bind(this)}
                />
              </SectionWithBorder>
            </div>
            {/* {this.state.basicApplicationDetails.statusId === 22 ?
              <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
                <div className="flex items-center justify-end">
                  <div className="flex gap-3 items-center justify-between mt-3">
                    {this.state.isSendRenewalPaymentLinkLoader && this.state.isSendRenewalPaymentLinkLoader === true ?
                      <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue-border mr-5" />
                      :
                      <>
                        <button
                          className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                          onClick={() => {
                            this.sendPaymentLinkForCONQUASRenewalPayment();
                          }}
                        //disabled={this.state.isDisabledFlag ? true : false}
                        >
                          Send Renewal Payment Link
                        </button>
                      </>
                    }
                  </div>
                </div>
              </div>
              : null} */}
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
              <div className="flex items-center justify-end">
                {/* {this.state.basicApplicationDetails.statusId === 22 ?
                  (
                    <div className="flex gap-3 items-center justify-between mt-3">
                      {this.state.isSendRenewalPaymentLinkLoader && this.state.isSendRenewalPaymentLinkLoader === true ?
                        <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue-border mr-5" />
                        :
                        <>
                          <button
                            className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                            onClick={() => {
                              this.sendPaymentLinkForCONQUASRenewalPayment();
                            }}
                          //disabled={this.state.isDisabledFlag ? true : false}
                          >
                            Send Renewal Payment Link
                          </button>
                        </>
                      }
                    </div>
                  )
                  :
                  null
                } */}

                {/* {(this.state.basicApplicationDetails.statusId === 2 || this.state.basicApplicationDetails.statusId === 18) && this.state.isAddedLOANotes === true ?  // this.state.basicApplicationDetails.loa !== null
                  <>
                    <div className="flex gap-3 items-center justify-between mt-3">
                      {this.state.isSendPaymentLinkLoader ? (
                        <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue-border mr-5" />
                      ) : (
                        <>
                          <button
                            className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 mr-5 ${this.state.basicApplicationDetails.loa ? null : "cursor-not-allowed"}`}
                            onClick={() => {
                              this.SentPaymentLinkOfCONQUASMember();
                            }}
                            disabled={
                              this.state.basicApplicationDetails.loa ? false : true
                            }
                          >
                            Send Payment link
                          </button>
                        </>
                      )
                      }
                    </div>
                  </>
                  :
                  null
                } */}
                {(this.state.basicApplicationDetails.statusId === 24 || this.state.isAddedLOANotes === true || this.state.basicApplicationDetails.statusId === 25) && this.state.basicApplicationDetails.statusId !== 4 ?
                  <div className="flex gap-3 items-center justify-between mt-3">


                    {this.state.isLoadingLOA ? (
                      <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue-border mr-5" />
                    ) : (
                      <>
                        <button
                          className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 mr-5`}
                          onClick={() => { this.setLOAFlag() }}
                        // disabled={
                        //   this.state.isDisabledFlag ? true : false
                        // }
                        >
                          LOA Notes
                        </button>
                      </>
                    )
                    }

                  </div>
                  :
                  null
                }
              </div>
            </div>
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
              <div className="flex items-center justify-end">
                <div className="flex gap-3 items-center justify-between mt-3">

                  {/* {this.state.basicApplicationDetails.statusId === 2 ? (
                    this.state.isSendPaymentLinkLoader ? (
                      <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue-border mr-5" />
                    ) : (
                      <>
                        <button
                          className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 mr-5`}
                          onClick={() => {
                            this.SentPaymentLinkOfCONQUASMember();
                          }}
                        // disabled={
                        //   this.state.isDisabledFlag ? true : false
                        // }
                        >
                          Send Payment link
                        </button>
                      </>
                    )
                  ) : null} */}
                  <div className="flex gap-3 items-center justify-between mr-5">

                    {this.state.basicApplicationDetails.statusId === 23 ? (
                      this.state.isBackFromClarificationLoader ? (
                        <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue mr-5" />
                      ) : (
                        <>
                          <button disabled={false}
                            className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                            //className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-[#f2f2f2] w-[250px] text-red p-3 mt-4 g-[#f2f2f2]`}
                            onClick={() => {
                              this.RevertToPreviousStatus();
                            }}
                          >
                            Back From Clarifications
                          </button>
                        </>
                      )
                    ) : null
                    }
                  </div>
                  {this.state.basicApplicationDetails.statusId === 27 ? (
                    this.state.isAcceptAndApprovedLoader ? (
                      <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue mr-5" />
                    ) : (
                      <>
                        <button disabled={false}
                          className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                          //className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-[#f2f2f2] w-[250px] text-red p-3 mt-4 g-[#f2f2f2]`}
                          onClick={() => {
                            this.acceptAndApprovedCONQUASApplication();
                          }}
                        >
                          Accept & Approved Application
                        </button>
                      </>
                    )
                  ) : null
                  }


                  {/* {this.state.basicApplicationDetails.statusId === 3 ? (
                    <button
                      className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 ${this.state.isDisabledFlag ? "cursor-not-allowed" : null
                        }`}
                    //onClick={this.setSendClarificationsFlag.bind(this)}
                    //disabled={this.state.isDisabledFlag ? true : false}
                    >
                      Send Clarifications
                    </button>
                  ) : null} */}
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex gap-3 items-center justify-between mt-3">

                    {/* {this.state.basicApplicationDetails.statusId == 2 ? ( */}
                    <button
                      className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                      onClick={this.setSendClarificationsFlag.bind(this)}
                    //disabled={this.state.isDisabledFlag ? true : false}
                    >
                      Send Clarifications
                    </button>
                    {/* ) : null} */}

                    {this.state.basicApplicationDetails.statusId === 12 ? (
                      <>
                        <button
                          className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                          onClick={() => {
                            this.rejectCONQUASMemberApplication("UnReject");
                          }}
                        //disabled={this.state.isDisabledFlag ? true : false}
                        >
                          Appeal Process

                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-red p-3 mt-4 `}
                          onClick={this.setRejectionReasonFlag.bind(this)}
                        //disabled={this.state.isDisabledFlag ? true : false}
                        >
                          Reject Application
                        </button>
                      </>
                    )}
                    {this.state.isLoading ? (
                      <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-blue" />
                    ) : (
                      <button
                        className={`col-end-9 col-span-2 text-xl font-bold btn btn-blue w-[250px] p-3 mt-4 `}
                        onClick={() => {
                          this.editCONQUASMemberDetails()
                        }}
                      // disabled={this.state.isDisabledFlag ? true : false}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* <div>
                <SectionWithBorder
                  totalColumn={1}
                  textSize="2xl"
                  className="font-bold"
                  column1="Employment & Project Information"
                >
                  <CONQUASTrainingInformation
                    countryList={this.state.countryList}
                    detail4={this.state.detail4}
                    employmentInformation={this.state.employmentInformation}
                    conquasSupervisonExperienceList={this.state.conquasSupervisonExperienceList}
                    validStateCONQUASMember={this.state.validStateCONQUASMember}
                    basicApplicationDetails={this.state.basicApplicationDetails}
                    isDisabledFlag={this.state.isDisabledFlag}
                    handleChange={this.handleChange.bind(this)}
                    handleChangeInDropdown={this.handleChangeInDropdown.bind(
                      this
                    )}
                  />
                </SectionWithBorder>
              </div> */}
            </div>

          </div>
          <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">

          </div>
        </div>
        <div className="membership-task-list-process-sec">
          <div className="send-clarification-model">
            <SendClarifications
              validState={this.state.validState}
              validateField={this.validateField.bind(this)}
              clarificationModal={this.state.setSendClarifications}
              clarification={this.state.clarification}
              isLoading={this.state.isLoading}
              setOpenModal={() =>
                this.setState({ setSendClarifications: false })
              }
              onTinyEceEditorChange={(data) =>
                this.handleChangeClarificationDetails(data)
              }
              isValidateAllFields1={this.state.isValidateAllFields1}
              // onClose={(e) => {e.stopPropagation();
              //   this.setState({ setSendClarifications: false });
              // }}
              saveClarificationDetails={this.saveClarificationDetails.bind(
                this
              )}
            />
          </div>
        </div>
        <div className="membership-task-list-process-sec">
          <div className="send-clarification-model">
            <SendRejectionReason
              rejectionModal={this.state.setRejectionReason}
              sendRejectionReason={this.state.sendRejectionReason}
              isLoadingRejectAplication={
                this.state.isLoadingRejectAplication
              }
              setOpenModal={() =>
                this.setState({ setRejectionReason: false })
              }
              onTinyEceEditorChange={(data) =>
                this.handleChangeSendRejectionReasonDetails(data)
              }
              // handleChangeSendRejectionReasonDetails={this.handleChangeSendRejectionReasonDetails.bind(this)}
              // onClose={(e) => {e.stopPropagation();
              //   this.setState({ setRejectionReason: false });
              // }}
              btn_color={"btn-orange"}
              rejectCONQUASMemberApplication={this.rejectCONQUASMemberApplication.bind(
                this
              )}
            />
          </div>
        </div>
        <div className="membership-task-list-process-sec">
          <div className="send-clarification-model">
            <UpdateLOA
              LOAModel={this.state.setUpdateLOA}
              loa={this.state.basicApplicationDetails.loa}
              isLoadingLOA={this.state.isLoadingLOA}
              setOpenModal={() =>
                this.setState({ setUpdateLOA: false })
              }
              onTinyEceEditorChange={(data) =>
                this.handleChangeLOAetails(data)
              }
              // onClose={(e) => {e.stopPropagation();
              //   this.setState({ setSendClarifications: false });
              // }}
              updateCONQUAsLOADetails={this.updateCONQUAsLOADetails.bind(
                this
              )}
            />
          </div>
        </div>
      </>
    );
  }
}