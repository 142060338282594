// Import react libraries and components
import React from 'react';
// import ValidationText from '../../utils/validations/ValidationText';
// import ButtonLoader from '../UIComponent/ButtonLoader';
import moment from 'moment';
// import { Image } from 'next/image';
import { RadioButton, TextInput } from '../../allTypesInputComponents/AllTypeInputComponents';
import ValidationText from '../../../utils/validation/ValidationText';
import DatePicker from '../../datePicker/DatePicker';
import ButtonLoader from '../../common/buttonLoader/ButtonLoader';
// import ValidationText from '../../../utils/validation/ValidationText';

export default function CONQUASTrainingInformation(props) {
    return (
        <>
            <div className='container mx-auto pb-10'>
                <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                    <div className='grid grid-cols-12 gap-4'>
                        <div className='col-span-12'>
                            <div className='md:mt-5 mt-5 bg-gray-box  '>
                                <div className='form-info-title'>
                                    <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                                        Current Employment Information
                                    </h4>
                                </div>
                                <div className='col-span-12 '>
                                    <div className="mb-2" >
                                        {/* <ValidationText error={props.validState.error.authorisedRepresentativeSummaryList} /> */}
                                    </div>
                                    <div className='table-section'>
                                        <div className='overflow-x-auto'>
                                            {/* <table className='w-full'>
                                            <thead className='bg-[#4687C6] text-white'>
                                                <tr>
                                                    <th className='text-left py-3 px-5'>
                                                        Name of Current Employer
                                                    </th>
                                                    <th className='text-left py-3 px-5'>
                                                        Office Address
                                                    </th>
                                                    <th className='text-left py-3 px-5'>
                                                        Designation
                                                    </th>
                                                    <th className='text-left py-3 px-5'>
                                                        Date Joined Company
                                                    </th>
                                                    <th className='text-left py-3 px-5'>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-sm custom-tbody">
                                                {props.conquasSupervisonExperienceList && props.conquasSupervisonExperienceList.map((empinfo, key) => {
                                                    return (
                                                        <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key}>
                                                            <td className='text-left py-2 px-5'>
                                                                {empinfo.nameOfEmployer}
                                                            </td>
                                                            <td className='text-left py-2 px-5'>
                                                                {empinfo.officeAddress}
                                                            </td>
                                                            <td className='text-left py-2 px-5'>
                                                                {empinfo.designation}
                                                            </td>
                                                            <td className='text-left py-2 px-5'>
                                                                {empinfo.dateJoinedCompany}
                                                            </td>
                                                            <td className='py-2 px-5 text-center'>
                                                                <button
                                                                    className='action-btn flex gap-2'
                                                                    // onClick={() => props.handleAddRemoveEducationalInfo("HandleRemove", "EmploymentInfo1", key)}
                                                                >
                                                                    <Image className='min-h-[30px] min-w-[30px] mx-auto' src='../edit.png' alt='' />
                                                                    <Image className='min-h-[30px] min-w-[30px] mx-auto' src='../delete-icon.svg' alt='' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table> */}
                                        </div>
                                    </div>
                                    <p className='mt-1 text-[14px]'>For new application, please submit scanned copies of letters of employment of
                                        your current employer and all previous employers. For renewal of certification, please submit a scanned
                                        copy of letter of employment of your current employer.</p>
                                </div>
                                <div className="grid grid-cols-12 md:gap-10 md:gap-6 gap-3 w-full justify-between mt-5 mb-5">
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Name of Current Employer
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                // type="text"
                                                name="nameOfCurrentEmployer"
                                                value={props.basicApplicationDetails.nameOfCurrentEmployer}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASTrainingInformation")}
                                                onBlur={() => props.validateField("nameOfCurrentEmployer", "Employment_Information")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.nameOfCurrentEmployer} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Office Address Line1
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="officeAddress"
                                                value={props.basicApplicationDetails.officeAddress}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASTrainingInformation")}
                                                onBlur={() => props.validateField("officeAddress", "Employment_Information")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.officeAddress} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Office Address Line2
                                            {/* <span className="text-[#C00000]"> *</span> */}
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="officeAddressLine2"
                                                value={props.basicApplicationDetails.officeAddressLine2}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASTrainingInformation")}
                                            // onBlur={() => props.validateField("officeAddressLine2", "Employment_Information")}
                                            />
                                            {/* <ValidationText error={props.validStateCONQUASMember.error.officeAddressLine2} /> */}
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Office Address Line3
                                            {/* <span className="text-[#C00000]"> *</span> */}
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="officeAddressLine3"
                                                value={props.basicApplicationDetails.officeAddressLine3}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASTrainingInformation")}
                                            // onBlur={() => props.validateField("officeAddressLine3", "Employment_Information")}
                                            />
                                            {/* <ValidationText error={props.validStateCONQUASMember.error.officeAddressLine3} /> */}
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Designation
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                className='w-full'
                                                name="designation"
                                                value={props.basicApplicationDetails.designation}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASTrainingInformation")}
                                                onBlur={() => props.validateField("designation", "Employment_Information")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.designation} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Date Joined Company
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <DatePicker
                                                name="dateJoinedCompany"
                                                className='w-full'
                                                value={moment(props.basicApplicationDetails.dateJoinedCompany).format("YYYY-MM-DD")}
                                                identity="Conquas_Application"
                                                handleChange={(e) => props.handleChange(e, "CONQUASTrainingInformation")}
                                                onBlur={() => props.validateField("dateJoinedCompany", "Employment_Information")}
                                            />
                                            <ValidationText error={props.validStateCONQUASMember.error.dateJoinedCompany} />
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Upload Letters of Employment
                                            <span className="text-[#C00000]"> *</span>
                                            <span className='input mr-5 text-[#808080]'> (PDF, DOC, DOCX files only)</span>
                                        </h2>
                                        <div className="flex item-center flex-col">
                                            {
                                                props && props.isLettersOfEmployment ?
                                                    <ButtonLoader type='btn btn-conquas' />
                                                    :
                                                    (
                                                        <>
                                                            <div className='eduinfo-uplaod-button flex'>
                                                                <form className="custom-uploader custom-file-upload-btn flex">
                                                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px]`}>
                                                                        <label
                                                                            htmlFor={`Letters_Of_Employment`}
                                                                            className={`text-xl text-white`}
                                                                        >
                                                                            Upload File
                                                                        </label>
                                                                        <input
                                                                            className="sr-only"
                                                                            id={`Letters_Of_Employment`}
                                                                            type="file"
                                                                            onChange={(event) => props.onFileChange(event, "Letters_Of_Employment")}
                                                                        />
                                                                    </div>
                                                                </form>
                                                                {props && props.employmentInformation.lettersOfEmployment ?
                                                                    <div className="w-auto">
                                                                        <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn-skyblue-border text-xl border text-[#4687C6]"
                                                                            onClick={() => props.GetCONQUASMemberFiles('LettersOfEmployment', props.basicApplicationDetails.lettersOfEmployment)}
                                                                        >
                                                                            <span className="text-xl text-[#4687C6] font-semibold tracking-normal underline">
                                                                                View Files
                                                                            </span>
                                                                        </button>
                                                                        {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                                    onClick={() => props.deleteFile(props.employmentInformation.lettersOfEmployment, "Letters_Of_Employment")}
                                                                >
                                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                                </button> */}
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                            }
                                            {
                                                props && props.basicApplicationDetails.lettersOfEmployment ?
                                                    (
                                                        <div className='mt-5 file-uploaded-txt flex items-center'>
                                                            {/* <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' /> */}
                                                            <span className='text-[#000]'>
                                                                {props.employmentInformation.lettersOfEmployment}
                                                            </span>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                            }
                                            <ValidationText error={props.validStateCONQUASMember.error.lettersOfEmployment} />
                                        </div>

                                    </div>
                                    <div className="col-span-12">
                                        {/* <div className='flex justify-end'>
                                            <button
                                                className='btn btn-border-conquas'
                                            // onClick={() => props.handleAddRemoveEducationalInfo("HandleAdd", "EmploymentInfo1")}
                                            >
                                                <span className='fa fa-plus'></span>
                                                <span className='ml-2'>
                                                    Add
                                                </span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='form-info-title'>
                                    <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                                        Details of Current and Past Site Supervision Experience
                                    </h4>
                                </div>
                                <div className='col-span-12 '>
                                    <div className="mb-2" >
                                        {/* <ValidationText error={props.validState.error.authorisedRepresentativeSummaryList} /> */}
                                    </div>
                                    <div className='table-section'>
                                        <div className='overflow-x-auto'>
                                            <table className='w-full'>
                                                <thead className='bg-[#4687C6] text-white'>
                                                    <tr>
                                                        <th className='text-left py-3 px-5'>
                                                            Name of Project
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Cost of Project
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Title of Position
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Date From
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Date To
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Name of Employer
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Address of Employer
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            CONQUAS Score of Project
                                                        </th>
                                                        {/* <th className='text-left py-3 px-5'>
                                                            Description of Project
                                                        </th> */}
                                                        <th className='text-left py-3 px-5'>
                                                            QM Project
                                                        </th>
                                                        <th className='text-left py-3 px-5'>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-sm custom-tbody">
                                                    {props.conquasSupervisonExperienceList && props.conquasSupervisonExperienceList.map((eduInfo4, key) => {
                                                        return (
                                                            <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key}>
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.nameOfProject}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.costOfProject}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.titleOfPosition}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {/* {eduInfo4.dateFrom} */}
                                                                    {moment(eduInfo4.dateFrom).format("DD MMM yyyy")}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {/* {eduInfo4.dateTo} */}
                                                                    {moment(eduInfo4.dateTo).format("DD MMM yyyy")}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.nameOfEmployer}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.addressOfEmployer}
                                                                </td>
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.conquasScoreOfProject}
                                                                </td>
                                                                {/* <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.descriptionOfProject}
                                                                </td> */}
                                                                <td className='text-left py-2 px-5'>
                                                                    {eduInfo4.isQMProject && eduInfo4.isQMProject === true ? "Yes" : "No"}
                                                                </td>
                                                                <td className='py-2 px-5 text-center'>
                                                                    <div className='flex gap-2'>
                                                                        <button
                                                                            className='action-btn flex gap-2'
                                                                            onClick={() => props.getSupervisionExperience(key)}
                                                                        >
                                                                            <img className='min-h-[30px] min-w-[30px] mx-auto' src='../edit.png' alt='' />

                                                                        </button>
                                                                        <button
                                                                            className='action-btn flex gap-2'
                                                                            onClick={() => props.deleteSupervisionExperience(eduInfo4.conquasSupervisionExperienceId)}
                                                                        >
                                                                            <img className='min-h-[30px] min-w-[30px] mx-auto' src='../delete-icon.svg' alt='' />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <p className='mt-4'>Please fill in details of past 5/8 years.</p> */}
                                    {/* <div className='top-listing2 mt-3'>
                                        <ul>
                                            <li>
                                               <p className='text-[14px] mb-2'>For new applications, please fill in details of past 5/8years</p> 
                                            </li>
                                            <li>
                                                <p className='text-[14px]  mb-2'>For renewal of certification, please fill in only details of past 3 years</p>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="grid grid-cols-12 md:gap-10 md:gap-4 gap-3 w-full justify-between mt-5">
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Name of Project
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="nameOfProject"
                                                value={props.detail4.nameOfProject}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("nameOfProject", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.nameOfProject} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Cost of Project
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="costOfProject"
                                                value={props.detail4.costOfProject}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("costOfProject", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.costOfProject} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Title of Position
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="titleOfPosition"
                                                value={props.detail4.titleOfPosition}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("titleOfPosition", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.titleOfPosition} />
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Date From
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                < DatePicker
                                                    name="dateFrom"
                                                    className='w-full'
                                                    value={moment(props.detail4.dateFrom).format("YYYY-MM-DD")}
                                                    identity="Supervision_Experience"
                                                    handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                    onBlur={() => props.validateField("dateFrom", "SupervisionExperience")}
                                                />
                                                <ValidationText error={props.validStateCONQUASSupervisionExperience.error.dateFrom} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Date To
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <DatePicker
                                                name="dateTo"
                                                className='w-full'
                                                value={moment(props.detail4.dateTo).format("YYYY-MM-DD")}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("dateTo", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.dateTo} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Name of Employer
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="nameOfEmployer"
                                                value={props.detail4.nameOfEmployer}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("nameOfEmployer", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.nameOfEmployer} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Address of Employer
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                className='w-full'
                                                name="addressOfEmployer"
                                                value={props.detail4.addressOfEmployer}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("addressOfEmployer", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.addressOfEmployer} />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            CONQUAS Score of Project
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                className='w-full'
                                                type="text"
                                                name="conquasScoreOfProject"
                                                value={props.detail4.conquasScoreOfProject}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("conquasScoreOfProject", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.conquasScoreOfProject} />
                                        </div>
                                    </div>
                                    {/* <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                    <h2 className="input-title mb-3">
                                        Upload Academic Qualifications and Course Certificates
                                        <span className="text-[#C00000]"> *</span>
                                    </h2>
                                    <div className="flex item-center flex-col">
                                        {
                                            props && props.isACRACerLoader ?
                                                <ButtonLoader type='btn btn-orange' />
                                                :
                                                (
                                                    <form className="custom-uploader custom-file-upload-btn flex">
                                                        <div className='border border-[#f3f3f3] btn btn-conquas cursor-pointer'>
                                                            <label
                                                                htmlFor={`Supervi_Exp_Academic_Qualification`}
                                                                className="text-uplod text-white cursor-pointer"
                                                            >
                                                                Upload File
                                                            </label>
                                                            <input
                                                                className="sr-only"
                                                                id={`Supervi_Exp_Academic_Qualification`}
                                                                type="file"
                                                                onChange={(event) => props.onFileChange(event, "Supervision_Experience_Academic_Qualification")}
                                                            />
                                                        </div>
                                                    </form>
                                                )
                                        }
                                        {
                                            props && props.detail4.certificatePath ?
                                                (
                                                    <div className='mt-5 file-uploaded-txt flex items-center'>
                                                        <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' />
                                                        <span className='text-[#000]'>
                                                            {props.detail4.certificatePath}
                                                        </span>
                                                    </div>
                                                )
                                                :
                                                null
                                        }
                                        <ValidationText error={props.validStateSupervisionExperience.error.certificatePath} />
                                    </div>
                                    </div> */}
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Upload Academic Qualifications and Course Certificates
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="flex item-center flex-col">
                                            {
                                                props && props.isSupervisionExperienceAndCourseCertificates ?
                                                    <ButtonLoader type='btn btn-blue' />
                                                    :
                                                    (
                                                        <>
                                                            <form className="custom-uploader custom-file-upload-btn flex">
                                                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px]`}>
                                                                    <label
                                                                        htmlFor={`AcademicQualificationsAndCourseCertificates`}
                                                                        className={`text-xl text-white`}
                                                                    >
                                                                        Upload File
                                                                    </label>
                                                                    <input
                                                                        className="sr-only"
                                                                        id="AcademicQualificationsAndCourseCertificates"
                                                                        type="file"
                                                                        onChange={(event) => props.onFileChange(event, "AcademicQualificationsAndCourseCertificates")}
                                                                    />
                                                                </div>
                                                            </form>
                                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.academicQualificationsAndCourseCertificates} />
                                                        </>
                                                    )
                                            }
                                            {
                                                props && props.detail4.academicQualificationsAndCourseCertificates ?
                                                    (
                                                        <div className='mt-5 file-uploaded-txt flex items-center'>
                                                            {/* <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' /> */}
                                                            <span className='text-[#000]'>
                                                                {props.detail4.academicQualificationsAndCourseCertificates} <button className='underline text-[#e74c3c] font-[500]'
                                                                    onClick={() => props.GetCONQUASMemberFiles('AcademicQualificationsAndCourseCertificates', props.detail4.academicQualificationsAndCourseCertificates)}
                                                                >
                                                                    View File
                                                                </button>
                                                            </span>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>






                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            QM Project? (Y/N)
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="flex items-center w-full">
                                            <div className="flex items-center">
                                                <RadioButton
                                                    id="isQMProject"
                                                    name="isQMProject"
                                                    className="form-radio-conquas"
                                                    value={props.detail4.isQMProject}
                                                    checked={props.detail4.isQMProject === true}
                                                    handleRadioButtons={(e) => props.handleChange(e, "Supervision_Experience_Radio")}
                                                />
                                                <span className="ml-3 text-[14px]">
                                                    Yes
                                                </span>
                                            </div>
                                            <div className="flex items-center ml-10">
                                                <RadioButton
                                                    id="isQMProject"
                                                    name="isQMProject"
                                                    className="form-radio-conquas"
                                                    value={props.detail4.isQMProject}
                                                    checked={props.detail4.isQMProject === false}
                                                    handleRadioButtons={(e) => props.handleChange(e, "Supervision_Experience_Radio")}
                                                />
                                                <span className="ml-3 text-[14px]">
                                                    No
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Upload Letters of Employment
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="flex item-center flex-col">
                                            {
                                                props && props.isLettersOfEmploymentSupervision ?
                                                    <ButtonLoader type='btn btn-conquas' />
                                                    :
                                                    (
                                                        <>
                                                            <form className="custom-uploader custom-file-upload-btn flex">
                                                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px]`}>
                                                                    <label
                                                                        htmlFor={`LettersOfEmployment`}
                                                                        className={`text-xl text-white`}
                                                                    >
                                                                        Upload File
                                                                    </label>
                                                                    <input
                                                                        className="sr-only"
                                                                        id="LettersOfEmployment"
                                                                        type="file"
                                                                        onChange={(event) => props.onFileChange(event, "LettersOfEmployment")}
                                                                    />
                                                                </div>
                                                            </form>
                                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.lettersOfEmployment} />
                                                        </>
                                                    )
                                            }
                                            {
                                                props && props.detail4.lettersOfEmployment ?
                                                    (
                                                        <div className='mt-5 file-uploaded-txt flex items-center'>
                                                            {/* <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' /> */}
                                                            <span className='text-[#000]'>
                                                                {props.detail4.lettersOfEmployment}
                                                                <button className='underline text-[#e74c3c] font-[500]'
                                                                    onClick={() => props.GetCONQUASMemberFiles('LettersOfEmploymentSupervision', props.detail4.lettersOfEmployment)}
                                                                >
                                                                    View File
                                                                </button>
                                                            </span>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>


                                    <div className="col-span-12 mt-6">
                                        <h2 className="input-title mb-3">
                                            Description of Project
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="descriptionOfProject"
                                                rows="10"
                                                className='w-full'
                                                maxLength={500}
                                                // placeholder={"Describe in detail types of structural and/or architectural workers supervised and degree of responsibilities in each engagement."}
                                                value={props.detail4.descriptionOfProject}
                                                identity="Supervision_Experience"
                                                handleChange={(e) => props.handleChange(e, "Supervision_Experience")}
                                                onBlur={() => props.validateField("descriptionOfProject", "SupervisionExperience")}
                                            />
                                            <ValidationText error={props.validStateCONQUASSupervisionExperience.error.descriptionOfProject} />
                                            {
                                                (props.characterLimit - props.detail4.descriptionOfProject?.length) <= 0 ?
                                                    (
                                                        <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                            You reach the character enter limit
                                                        </div>
                                                    )
                                                    :
                                                    props.characterLimit - props.detail4.descriptionOfProject?.length <= 500 ?
                                                        (
                                                            <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center mt-2 ${props.characterLimit - props.descriptionOfProject.descriptionOfProject?.length < 50 ?
                                                                "bg-[#C00000]"
                                                                : props.characterLimit -
                                                                    props.detail4.descriptionOfProject?.length <
                                                                    100 ?
                                                                    "bg-[#ffc107]"
                                                                    : "bg-blue-500"}`}>
                                                                {props.characterLimit - props.detail4.descriptionOfProject?.length}/{props.characterLimit}
                                                            </div>
                                                        )
                                                        :
                                                        null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-span-12 mt-8">
                                        <div className='flex justify-end'>
                                            <button
                                                className='btn btn-border-conquas'
                                                onClick={() => props.handleAddRemoveEducationalInfo("HandleAdd", "EmploymentInfo2")}
                                            >
                                                <span className='fa fa-plus'></span>
                                                <span className='ml-2'>
                                                    {props.detail4.conquasSupervisionExperienceId > 0 ? "Update" : "Add"}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={`flex flex-wrap ${props.isRenewal === true ? "justify-end" : "justify-between"}   mt-16`}>

                            {props && props.isDraftLoader ?
                                <ButtonLoader type='btn btn-border-conquas' />
                                :
                                <button
                                    className='btn btn-border-conquas'
                                    onClick={() => props.submitOrganisationProfile("isDraft")}
                                >
                                    <span className='ml-2'>
                                        Save as Draft
                                    </span>
                                </button>
                            }
                            {
                                props && props.isSubmitLoader ?
                                    <ButtonLoader type='btn btn-conquas' />
                                    :
                                    <button
                                        className='btn btn-conquas'
                                        onClick={() => props.submitOrganisationProfile("isSave")}
                                    >
                                        <span className=''>
                                            Next
                                        </span>
                                    </button>
                            }
                        </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}